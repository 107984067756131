<template>
  <div class="base-tab flex w-full text-f-black-50 text-16">
    <div
      v-for="item in items"
      :key="item.value"
      class="
        item
        flex-1 flex
        items-center
        justify-center
        transition-colors
        duration-500
      "
      :class="{active: active == item.value}"
      @click="$emit('update', item.value)"
    >
      {{ item.text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseTab',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    active: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
.base-tab {
  height: 35px;
  @apply border-b border-solid border-gray;

  .item {
    &.active {
      @apply border-b-2 border-solid border-primary text-primary;
    }
  }
}
</style>
