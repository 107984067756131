<template>
  <div id="app" class="flex flex-col relative w-screen h-full">
    <Header
      v-show="routeName != 'login'"
      :title="headerConfig.title"
      :left="headerConfig.left"
      :left-customized="headerConfig.leftCustomized"
      :middle="headerConfig.middle"
      :right="headerConfig.right"
      :right-icons="headerConfig.rightIcons"
      :nextText="headerConfig.nextText"
      :nextDisabled="headerConfig.nextDisabled"
      @back="back"
      @close="close"
      @next="next"
      @icon-action="iconAction"
    ></Header>
    <div
      class="relative flex-1 page mx-auto h-full w-full overflow-auto"
      :class="[
        {
          'show-app-footer': isFooterShow,
        },
        ['web', 'pad'].includes(viewMode) &&
        (!isCapacitorApp || device !== 'ios')
          ? 'show-web-footer'
          : 'container',
      ]"
    >
      <router-view />
      <loading
        color="#5984DE"
        background-color="#F7F8FC"
        loader="bars"
        :active.sync="isLoading"
        :is-full-page="false"
        :width="50"
        :height="50"
      ></loading>
    </div>
    <Footer
      v-if="!['web', 'pad'].includes(viewMode)"
      v-show="isFooterShow"
    ></Footer>
    <div
      v-else
      class="web-footer text-14 text-center"
      style="color: rgb(83 ,81 ,93, 0.5)"
    >
      Copyright © 2022 海峰棋院. All rights reserved.
    </div>
    <Login
      class="z-modal absolute top-0 left-0 w-full h-full"
      v-if="isLoginModalShow"
    ></Login>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Login from '@/components/Login.vue';
import UPDATE_KEYBOARD_SHOW from '@/graphql/mutations/UpdateKeyboardShow.gql';
import GET_SETTING from '@/graphql/queries/GetSetting.gql';
import READ_HEADER_CONFIG from '@/graphql/queries/ReadHeaderConfig.gql';
import READ_LOADING from '@/graphql/queries/ReadLoading.gql';
import READ_LOGIN_MODAL_SHOW from '@/graphql/queries/ReadLoginModalShow.gql';
import soundService from '@/lib/base/soundService';
import getDeviceInfo from '@/lib/getDeviceInfo';
import {Device} from '@capacitor/device';
import {Keyboard} from '@capacitor/keyboard';
const {viewMode, isCapacitorApp, device} = getDeviceInfo();

require('wgo.js/wgo/wgo.js');
require('wgo.js/wgo/kifu.js');
require('wgo.js/wgo/sgfparser.js');
require('wgo.js/wgo/player.js');
require('wgo.js/wgo/basicplayer.js');
require('wgo.js/wgo/basicplayer.component.js');
require('wgo.js/wgo/basicplayer.infobox.js');
require('wgo.js/wgo/basicplayer.commentbox.js');
require('wgo.js/wgo/basicplayer.control.js');
require('wgo.js/wgo/player.editable.js');
require('wgo.js/wgo/scoremode.js');
require('wgo.js/wgo/player.permalink.js');

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Loading,
    Login,
  },
  apollo: {
    headerConfig: {
      query: READ_HEADER_CONFIG,
    },
    isLoading: {
      query: READ_LOADING,
    },
    setting: {
      query: GET_SETTING,
    },
    isLoginModalShow: {
      query: READ_LOGIN_MODAL_SHOW,
    },
  },
  data() {
    return {
      headerConfig: {},
      deviceInfo: {},
      viewMode,
      isCapacitorApp,
      device,
    };
  },
  computed: {
    routeName() {
      return this.$route.name || '';
    },
    routeMeta() {
      return this.$route.meta;
    },
    isFooterShow() {
      return ['home', 'kifu-category', 'newses', 'more'].includes(
        this.routeName,
      );
    },
    isFooterExist() {
      return (
        !['apple-auth'].includes(this.routeName) &&
        this.deviceInfo.platform !== 'web'
      );
    },
  },
  watch: {
    setting: {
      deep: true,
      handler(value) {
        if (value.isLoaziSound) {
          soundService.able('play');
        } else {
          soundService.disable('play');
        }
      },
    },
  },
  async created() {
    const deviceInfo = await Device.getInfo();
    this.deviceInfo = deviceInfo;
    if (deviceInfo.platform != 'web') {
      Keyboard.addListener('keyboardWillShow', () => {
        this.$apollo.mutate({
          mutation: UPDATE_KEYBOARD_SHOW,
          variables: {
            isKeyboardShow: true,
          },
        });
      });
      Keyboard.addListener('keyboardWillHide', () => {
        this.$apollo.mutate({
          mutation: UPDATE_KEYBOARD_SHOW,
          variables: {
            isKeyboardShow: false,
          },
        });
      });
    }
  },
  methods: {
    back() {
      this.$bus.$emit('header:back');
    },
    next() {
      this.$bus.$emit('header:next');
    },
    close() {
      this.$bus.$emit('header:close');
    },
    iconAction(actionName) {
      this.$bus.$emit(`header:icon-${actionName}`);
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  font-family: Microsoft JhengHei;
  @apply text-f-black bg-background;

  .page {
    height: calc(100% - 44px);

    &.show-app-footer {
      height: calc(100% - 98px);
    }
    &.show-web-footer {
      height: calc(100% - 92px);
    }
  }
  .web-footer {
    height: 48px;
    @apply flex justify-center items-center border-t border-solid border-gray;
  }
}
</style>

<style lang="scss">
body {
  @apply bg-i-dutch-blue;
  padding-top: constant(safe-area-inset-top);
  padding-right: constant(safe-area-inset-right);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-left: constant(safe-area-inset-left);
  padding-top: env(safe-area-inset-top);
  padding-right: env(safe-area-inset-right);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  height: 100vh;
}

#app {
  z-index: 1;
  button:focus {
    @apply border border-solid border-i-dutch-blue;
    outline: none;
  }
}
</style>
