<template>
  <div
    class="search-panel fixed left-0 w-full h-full text-14"
    :class="{show, mask}"
    @click.self="$emit('close')"
  >
    <div class="content absolute w-full flex flex-col rounded-t-10 bg-white">
      <div class="row flex justify-center">
        <slot name="header"></slot>
      </div>
      <BaseLine />
      <div class="flex-1 flex flex-col overflow-scroll">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchBar',
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    mask: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss">
.search-panel {
  &.show {
    &.mask {
      @apply bg-black-50;
    }
    @apply top-0;
    .content {
      top: 12%;
    }
  }
  &:not(.show) {
    transition: top 0.7s step-end;
    @apply top-p100;
    background-color: inherit;
    .content {
      @apply top-p100;
    }
  }
  .content {
    @apply transition-all duration-700;
    height: 88%;

    .row {
      @apply w-full items-center pl-md pr-md;
      height: 44px;
    }
  }
  i {
    @apply absolute right-md text-14 font-bold;
  }
  i.icon-Check {
    &:before {
      @apply text-i-green;
    }
  }
  i.icon-LineRight {
    &:before {
      @apply text-i-gray;
    }
  }
}
</style>
