import UPDATE_LOADING from '@/graphql/mutations/UpdateLoading.gql';

const mixinObject = {
  watch: {
    '$apollo.loading': {
      immediate: true,
      handler(value) {
        this.$apollo.mutate({
          mutation: UPDATE_LOADING,
          variables: {
            isLoading: value,
          },
        });
      },
    },
  },
};

export default mixinObject;
