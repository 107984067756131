import READ_HEADER_CONFIG from './queries/ReadHeaderConfig.gql';
import READ_SGF from './queries/ReadSgf.gql';
import GET_SETTING from './queries/GetSetting.gql';
import READ_LOADING from './queries/ReadLoading.gql';
import READ_KEYBOARD_SHOW from './queries/ReadKeyboardShow.gql';
import READ_LOGIN_MODAL_SHOW from './queries/ReadLoginModalShow.gql';
import {defaultHeaderConfig, defaultSetting} from './defaultState';

export default {
  Mutation: {
    setHeaderConfig: (_, {headerConfig, update = false}, {cache}) => {
      const data = cache.readQuery({query: READ_HEADER_CONFIG});
      if (update) {
        Object.assign(data.headerConfig, headerConfig);
      } else {
        data.headerConfig = Object.assign(
          {},
          defaultHeaderConfig,
          headerConfig,
        );
      }
      cache.writeQuery({query: READ_HEADER_CONFIG, data});
      return null;
    },
    setSgf: (_, {sgf}, {cache}) => {
      const data = cache.readQuery({query: READ_SGF});
      data.sgf = sgf;
      cache.writeQuery({query: READ_SGF, data});
      return null;
    },
    setSetting: (_, {setting, update = false}, {cache}) => {
      const data = cache.readQuery({query: GET_SETTING});
      if (update) {
        Object.assign(data.setting, setting);
      } else {
        data.setting = Object.assign({}, defaultSetting, setting);
      }
      cache.writeQuery({query: GET_SETTING, data});
      localStorage.setItem('setting', JSON.stringify(data.setting));
      return null;
    },
    setKeyboardShow: (_, {isKeyboardShow}, {cache}) => {
      const data = cache.readQuery({query: READ_KEYBOARD_SHOW});
      data.isKeyboardShow = isKeyboardShow;
      cache.writeQuery({query: READ_KEYBOARD_SHOW, data});
      return null;
    },
    setLoginModalShow: (_, {isLoginModalShow}, {cache}) => {
      const data = cache.readQuery({query: READ_LOGIN_MODAL_SHOW});
      data.isLoginModalShow = isLoginModalShow;
      cache.writeQuery({query: READ_LOGIN_MODAL_SHOW, data});
      return null;
    },
    setLoading: (_, {isLoading}, {cache}) => {
      const data = cache.readQuery({query: READ_LOADING});
      data.isLoading = isLoading;
      cache.writeQuery({query: READ_LOADING, data});
      return null;
    },
  },
};
