<template>
  <div class="avatar relative" :style="avatarStyle">
    <img class="h-full w-full rounded-full" :src="showUrl" />
    <div class="dot" v-show="color" :class="color" :style="dotStyle"></div>
  </div>
</template>

<script>
import defaultAvatar from '@/assets/img/default-avatar.png';

export default {
  name: 'Avatar',
  props: {
    url: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      validator(value) {
        return ['black', 'white'].includes(value);
      },
    },
    width: {
      type: Number,
      default: 30,
    },
    dotWidth: {
      type: Number,
      default: 16,
    },
  },
  computed: {
    showUrl() {
      return this.url || defaultAvatar;
    },
    avatarStyle() {
      return {
        width: `${this.width}px`,
      };
    },
    dotStyle() {
      return {
        width: `${this.dotWidth}px`,
        height: `${this.dotWidth}px`,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.avatar {
  .dot {
    right: -3px;
    bottom: -3px;
    @apply absolute rounded-full border-3 border-solid border-white;
    &.black {
      @apply bg-f-black;
    }
    &.white {
      @apply bg-i-gray;
    }
  }
}
</style>
