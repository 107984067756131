<template>
  <div id="kifu" class="relative flex flex-col h-full max-h-full">
    <!-- 直播列 -->
    <div
      v-if="kifu.isStreaming"
      v-show="!isOnlyBoard"
      class="text-center bg-secondary text-12 text-i-white font-bold"
    >
      直播中
    </div>
    <!-- 上方區塊 -->
    <div
      class="
        bg-background
        border-b border-solid border-gray
        p-sm
        w-full
        overflow-y-scroll
      "
      :class="
        ['view', 'single'].includes(action) ? '' : 'flex-1 flex items-center'
      "
      :style="isOnlyBoard ? {height: 'calc(100% - 56px)'} : {}"
    >
      <!-- 使用者資訊 -->
      <div
        v-show="!isOnlyBoard && ['view', 'single'].includes(action)"
        class="flex justify-between"
      >
        <div class="flex items-center">
          <BaseAvatar :url="kifu.blackPlayer.avatarUrl" color="black" />
          <div class="player-name ml-sm">{{ kifu.blackPlayer.name }}</div>
        </div>
        <div class="flex items-center text-primary">
          {{ showStep }} / {{ showCompleteStep }}
        </div>
        <div class="flex items-center">
          <div class="player-name text-right">{{ kifu.whitePlayer.name }}</div>
          <BaseAvatar
            class="ml-sm"
            :url="kifu.whitePlayer.avatarUrl"
            color="white"
          />
        </div>
      </div>
      <!-- 棋盤 -->
      <Board
        id="player"
        class="mt-13"
        :testPlay="isTestPlay"
        :lock="isBoardLock"
        :full="isOnlyBoard ? 'height' : 'width'"
      ></Board>
    </div>
    <!-- Tab -->
    <div v-show="!isOnlyBoard && action == 'view'" class="bg-white">
      <BaseTab :items="tabItems" :active="tab" @update="setTab"></BaseTab>
    </div>
    <!-- Tab內容 -->
    <div
      v-show="!isOnlyBoard && ['view', 'single'].includes(action)"
      class="bg-white flex-1 flex flex-col overflow-y-scroll"
      style="min-height: 80px"
      ref="tabContainer"
    >
      <!-- tab: 解說(一般人視角) -->
      <div
        v-if="!isCommentator"
        v-show="tab == 'comment' && action == 'view'"
        class="tab-content"
      >
        <div v-show="showVariations.length > 0" class="flex flex-wrap">
          <VariationTag
            v-for="(variation, index) in showVariations"
            :key="index"
            class="mr-sm mb-xs"
            :step="variation.step"
            :serial="index + 1"
            @click="viewVariation(variation, index + 1)"
          ></VariationTag>
        </div>
        <Comment
          v-for="(comment, index) in showCommentatorComments"
          :key="index"
          :nickname="comment.createdUserName"
          :step="comment.step"
          :content="comment.content"
          :editButton="false"
        ></Comment>
      </div>
      <div
        v-if="!isCommentator && action == 'single'"
        v-show="tab == 'comment'"
        class="tab-content"
      >
        {{ selectedVariation.comment }}
      </div>
      <!-- tab: 解說(解說員視角) -->
      <div v-if="isCommentator" v-show="tab == 'comment'" class="tab-content">
        <Comment
          v-for="(comment, index) in showCommentatorComments"
          :key="index"
          :nickname="comment.createdUserName"
          :step="comment.step"
          :content="comment.content"
          :editButton="comment.createdBy.id === (user && user.id)"
          @edit="editComment(comment)"
        ></Comment>
        <div v-show="!hasSelfCommentatorComment" class="py-sm">
          <BaseButton @click="action = 'input'" size="full"
            >+新增解說</BaseButton
          >
        </div>
      </div>
      <!-- tab: 變化圖(解說員視角) -->
      <div v-if="isCommentator" v-show="tab == 'variation'" class="tab-content">
        <div v-show="action == 'view'" class="h-full">
          <div v-show="showVariations.length > 0" class="flex flex-wrap">
            <VariationTag
              v-for="(variation, index) in showVariations"
              :key="index"
              class="mr-sm mb-xs"
              :step="variation.step"
              :serial="index + 1"
              @click="editVariation(variation)"
            ></VariationTag>
            <div
              class="add-more bg-i-orange text-i-white"
              @click="wantInputVariation"
            >
              <i class="icon-Add" />
            </div>
          </div>
          <div v-show="showVariations.length == 0" class="py-sm">
            <BaseButton type="warning" @click="wantInputVariation" size="full"
              >+新增變化圖</BaseButton
            >
          </div>
        </div>
      </div>
      <!-- tab: 留言 -->
      <div v-show="tab == 'message'" class="tab-content">
        <Comment
          v-for="(comment, index) in showNormalUserComments"
          :key="index"
          :nickname="comment.createdUserName"
          :step="comment.step"
          :content="comment.content"
          :editButton="false"
        ></Comment>
      </div>
    </div>
    <!-- Footer -->
    <div class="flex flex-wrap items-center border-t border-solid border-gray">
      <!-- footer: 新增解說 -->
      <div
        v-show="action == 'input' && tab == 'comment'"
        class="kifu-footer kifu-footer-item pl-md pr-md"
      >
        <BaseInput
          v-model="commentContent"
          :keyboardShowKeepBottom="isKeyboardShow"
          :placeholder="'請輸入第' + step + '手解說文字'"
          :full="true"
          @confirm="hideKeyboard"
        ></BaseInput>
      </div>
      <!-- footer: 編輯解說 -->
      <div v-if="action == 'edit' && tab == 'comment'" class="kifu-footer">
        <div class="kifu-footer-item pl-md pr-md">
          <BaseInput
            v-model="commentContent"
            :keyboardShowKeepBottom="isKeyboardShow"
            :placeholder="'請輸入第' + step + '手解說文字'"
            :full="true"
            @confirm="hideKeyboard"
          ></BaseInput>
        </div>
        <BaseLine v-show="!isOnlyBoard" class="w-full" />
        <div v-show="!isOnlyBoard">
          <div
            class="
              kifu-footer-item
              text-i-red
              font-bold
              flex
              items-center
              justify-center
            "
            @click="deleteComment"
          >
            <i class="icon-Delete"></i>刪除解說
          </div>
        </div>
      </div>
      <!-- footer: 新增變化圖 -->
      <div v-show="action == 'input' && tab == 'variation'" class="kifu-footer">
        <div class="kifu-footer-item pl-md pr-md">
          <BaseInput
            v-model="commentContent"
            :keyboardShowKeepBottom="isKeyboardShow"
            :placeholder="
              '請輸入變化圖' +
                variationStep +
                '-' +
                (showVariations.length + 1) +
                '說明文字'
            "
            :full="true"
          ></BaseInput>
        </div>
        <BaseLine class="w-full" />
      </div>
      <!-- footer: 編輯變化圖 -->
      <div v-if="action == 'edit' && tab == 'variation'" class="kifu-footer">
        <BaseInput
          v-model="commentContent"
          :keyboardShowKeepBottom="isKeyboardShow"
          :placeholder="
            '請輸入變化圖' +
              selectedVariation.step +
              '-' +
              selectedVariationSerial +
              '說明文字'
          "
          :full="true"
          @confirm="hideKeyboard"
        ></BaseInput>
        <BaseLine v-show="!isOnlyBoard" class="w-full" />
      </div>
      <!-- footer: 新增留言 -->
      <div
        v-show="isMessageInputShow"
        class="kifu-footer kifu-footer-item pl-md pr-md"
      >
        <button
          class="message-back mr-sm flex items-center justify-center"
          @click="isMessageInputShow = !isMessageInputShow"
        >
          <i class="icon-LineLeft" />
        </button>
        <BaseInput
          v-if="user"
          :placeholder="commentAble ? '請輸入留言' : '你已被禁止發言'"
          :disabled="!commentAble"
          :right="commentAble ? 'submit' : ''"
          v-model="commentContent"
          :full="true"
          @submit="addComment(false)"
        ></BaseInput>
        <BaseInput
          v-if="!user"
          placeholder="訪客身份無法留言"
          right="submit"
          disabled
          :full="true"
          @click="openLoginModal"
        ></BaseInput>
      </div>
      <!-- footer: 棋譜控制列 -->
      <KifuControll
        v-show="!isOnlyBoard && isKifuControlShow && !isMessageInputShow"
        :value="showStep"
        :max="completeStep"
        :showStreamAction="isCommentator && kifu.isStreaming && isStreamCreator"
        :testPlay="isTestPlay"
        :coordinate="isCoordinatesShow"
        :variationShow="isVariationShow"
        :tab="tab"
        @input="updateStep"
        @click="onBoardControlerClick"
        @update-coordinate="toggleCoordinates"
        @toggle-step="toggleStep"
        @update-test-play="toggleTestPlay"
        @undo="undo"
        @end-stream="endStream"
        @leave-variation="reset"
        @open-message="openMessage"
        class="w-full"
      ></KifuControll>
      <!-- footer: 刪除變化圖 -->
      <div v-if="action == 'edit' && tab == 'variation'" class="kifu-footer">
        <BaseLine v-show="!isOnlyBoard" class="w-full" />
        <div v-show="!isOnlyBoard">
          <div
            class="
              kifu-footer-item
              text-i-red
              font-bold
              flex
              items-center
              justify-center
            "
            @click="deleteVariation"
          >
            <i class="icon-Delete"></i>刪除變化圖
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <KifuDetail
      class="absolute top-0 left-0"
      v-if="isKifuDetailShow"
      :kifu="kifu"
      @close="closeKifuDetail"
    ></KifuDetail>
  </div>
</template>

<script>
import _ from 'lodash';
import WgoHelper from 'wgo-helper';
import boardMixin from '@/mixins/boardMixin';
import loadingMixin from '@/mixins/loadingMixin';
import kifuMixin from '@/mixins/kifuMixin';
import Board from '@/components/Common/Board';
import KifuControll from '@/components/Kifu/KifuControll';
import VariationTag from '@/components/Kifu/VariationTag';
import KifuDetail from '@/components/Kifu/KifuDetail';
import Comment from '@/components/Kifu/Comment';
import GET_SETTING from '@/graphql/queries/GetSetting.gql';
import GET_USER from '@/graphql/queries/GetUser.gql';
import GET_PUBLIC_KIFU from '@/graphql/queries/GetPublicKifu.gql';
import READ_KEYBOARD_SHOW from '@/graphql/queries/ReadKeyboardShow.gql';
import UPDATE_HEADER_CONFIG from '@/graphql/mutations/UpdateHeaderConfig.gql';
import UPDATE_PUBLIC_KIFU from '@/graphql/mutations/UpdatePublicKifu.gql';
import UPDATE_LOGIN_MODAL_SHOW from '@/graphql/mutations/UpdateLoginModalShow.gql';
import SUBSCRIPTIONS from '@/graphql/subscriptions.gql';
import dayjs from 'dayjs';
import {Share} from '@capacitor/share';

export default {
  name: 'Kifu',
  components: {
    Board,
    KifuControll,
    VariationTag,
    Comment,
    KifuDetail,
  },
  mixins: [boardMixin, loadingMixin, kifuMixin],
  apollo: {
    setting: {
      query: GET_SETTING,
    },
    user: {
      query: GET_USER,
    },
    isKeyboardShow: {
      query: READ_KEYBOARD_SHOW,
    },
    publicKifu: {
      query: GET_PUBLIC_KIFU,
      variables() {
        return {
          id: Number(this.$route.params.id),
        };
      },
      result({data, loading}) {
        if (!loading && data) {
          this.kifu = data.publicKifu;
          this.comments = JSON.parse(
            JSON.stringify(data.publicKifu.comments || []),
          );
          this.variations = JSON.parse(
            JSON.stringify(data.publicKifu.variations || []),
          );
          this.initialPlayer(this.kifu.sgf);
          this.initialHeaderConfig();
        }
      },
      skip() {
        return this.kifu?.id != null;
      },
    },
    $subscribe: {
      enterStream: {
        query: SUBSCRIPTIONS,
        variables() {
          return {
            kifuId: this.id,
          };
        },
        result({data}) {
          const response = data.enterStream;
          if (response.__typename == 'MoveSubscription') {
            if (this.wgoHelper.sgf != response.sgf) {
              this.wgoHelper.sgf = response.sgf;
            }
          } else if (response.__typename == 'CreateCommentSubscription') {
            this.comments.push(response);
          } else if (response.__typename == 'DeleteCommentSubscription') {
            const index = this.comments.findIndex(
              (comment) => comment.id == response.id,
            );
            if (index >= 0) {
              this.comments.splice(index, 1);
            }
          } else if (response.__typename == 'UpdateCommentSubscription') {
            const findComment = this.comments.find(
              (comment) => comment.id == response.id,
            );
            if (findComment) {
              findComment.content = response.content;
            }
          } else if (response.__typename == 'CreateVariationSubscription') {
            this.variations.push(response);
          } else if (response.__typename == 'DeleteVariationSubscription') {
            const index = this.variations.findIndex(
              (variation) => variation.id == response.id,
            );
            if (index >= 0) {
              this.variations.splice(index, 1);
            }
          } else if (response.__typename == 'UpdateVariationSubscription') {
            const findVariation = this.variations.find(
              (variation) => variation.id == response.id,
            );
            if (findVariation) {
              findVariation.comment = response.updatedComment;
              findVariation.sgf = response.updatedSgf;
            }
          } else if (response.__typename == 'StreamEndedSubscription') {
            this.kifu.isStreaming = false;
            if (this.kifu.createdBy.id == this.user?.id) {
              this.$Message.success('已結束直播');
            } else {
              this.$Message.info(
                `直播已結束(${response.winningWay})`,
                1000 * 5,
              );
            }
          }
        },
      },
    },
  },
  data() {
    /**
     * tab: comment 留言
     *   action: view 檢視留言
     *   action: input 輸入留言
     *   action: edit 編輯留言
     * tab: variation 變化圖
     *   action: view 檢視變化圖
     *   action: input 新增變化圖
     *   action: single 檢視單一變化圖
     */
    return {
      kifuMode: 'live',
      updateMutation: UPDATE_PUBLIC_KIFU,
      kifu: {
        series: {
          name: '',
          tournament: {
            name: '',
          },
        },
        blackPlayer: {
          name: '',
        },
        whitePlayer: {
          name: '',
        },
      },
    };
  },
  computed: {
    permissions() {
      return this.user?.role?.permissions || [];
    },
    commentatorComments() {
      return this.comments.filter((comment) => comment.isCreatedByCommentator);
    },
    normalUserComments() {
      return this.comments.filter((comment) => !comment.isCreatedByCommentator);
    },
    showCommentatorComments() {
      return this.commentatorComments.filter(
        (comment) => comment.step == this.step,
      );
    },
    showNormalUserComments() {
      return this.normalUserComments.filter(
        (comment) => comment.step == this.step,
      );
    },
    hasSelfCommentatorComment() {
      return this.showCommentatorComments.some(
        (comment) => comment.createdBy.id == this.user?.id,
      );
    },
    isCommentator() {
      return this.permissions.some((permission) => permission.code == 'STREAM');
    },
    isStreamCreator() {
      return this.kifu.createdBy.id === this.user.id;
    },
    tabItems() {
      if (this.isCommentator) {
        return [
          {text: '解說', value: 'comment'},
          {text: '變化圖', value: 'variation'},
          {text: '留言', value: 'message'},
        ];
      }
      return [
        {text: '解說', value: 'comment'},
        {text: '留言', value: 'message'},
      ];
    },
    isBoardLock() {
      if (this.isCommentator && this.kifu.isStreaming && this.isStreamCreator) {
        if (
          this.isTestPlay ||
          (this.tab == 'variation' && this.action == 'input') ||
          (this.step == this.completeStep && this.action != 'input')
        ) {
          return false;
        }
        return true;
      }
      return !this.isTestPlay;
    },
    commentAble() {
      const now = dayjs();
      const muteTime = dayjs(this.user.bannedPostEndAt);
      return muteTime.diff(now, 'month', true) < 0;
    },
  },
  watch: {
    showNormalUserComments(newComments, oldComments) {
      if (
        newComments.length > oldComments.length &&
        newComments[newComments.length - 1].createdBy.id == this.user?.id
      ) {
        this.$nextTick(() => {
          const tabContainer = this.$refs.tabContainer;
          tabContainer.scrollTop = tabContainer.scrollHeight;
        });
      }
    },
  },
  created() {
    this.$bus.$on('header:icon-Info', () => {
      this.showKifuDetail();
    });
    this.$bus.$on('header:icon-Share', () => {
      this.shareKifu();
    });
  },
  beforeDestroy() {
    this.$bus.$off('header:icon-Info');
    this.$bus.$off('header:icon-Share');
  },
  methods: {
    async shareKifu() {
      await Share.share({
        url: `https://${window.location.hostname}/${window.location.hash}/`,
      });
    },
    initialPlayer(sgf = '(;CA[big5]SZ[19])') {
      const theme = {stoneHandler: 'GLOW'};
      this.wgoHelper = new WgoHelper('game', 'player', sgf, {
        theme,
      });

      this.wgoHelper.on('play', () => {
        this.$playSound('play');
        if (!this.isTestPlay) {
          this.updateSgfDebounce();
        }
      });
      this.wgoHelper.on('update', () => {
        this.capCount = this.wgoHelper.captureCount;
        this.turnColor = this.wgoHelper.turnColor;
        if (this.isTestPlay) {
          this.testPlayCompleteStep = this.wgoHelper.completeStep;
        }
        this.completeStep = this.wgoHelper.completeStep;
        if (this.isTestPlay) {
          this.testPlayStep = this.wgoHelper.step;
        } else {
          this.step = this.wgoHelper.step;
        }
      });
      this.isCoordinatesShow = this.wgoHelper.toggleCoordinates(true);
      this.wgoHelper.last();
      this.capCount = this.wgoHelper.captureCount;
      this.turnColor = this.wgoHelper.turnColor;
      this.completeStep = this.wgoHelper.completeStep;
      this.step = this.wgoHelper.step;
    },
    setTab(value) {
      this.tab = value;
      if (this.tab == 'message') {
        this.$nextTick(() => {
          const tabContainer = this.$refs.tabContainer;
          tabContainer.scrollTop = tabContainer.scrollHeight;
        });
      }
    },
    updateSgfDebounce: _.debounce(
      function() {
        this.updateSgf();
      },
      400,
      {maxWait: 1000 * 1.5},
    ),
    async updateSgf(sgf = this.wgoHelper.sgf) {
      if (!this.isCommentator) {
        return;
      }
      await this.$apollo.mutate({
        mutation: UPDATE_PUBLIC_KIFU,
        variables: {
          input: {
            id: this.id,
            sgf,
          },
        },
      });
    },
    undo() {
      if (this.wgoHelper.step != this.completeStep) {
        this.$Message.info('只允許撤回最後一步');
        return;
      }
      this.wgoHelper.sgf = this.wgoHelper.getSgfOnStep(
        this.wgoHelper.sgf,
        this.wgoHelper.step - 1,
      );
      this.$Message.success('已成功撤回棋子');
    },
    async endStream(winningWay) {
      if (!winningWay) {
        this.$Message.error('請輸入勝負結果');
        return;
      }
      await this.$apollo.mutate({
        mutation: UPDATE_PUBLIC_KIFU,
        variables: {
          input: {
            id: this.id,
            isStreaming: false,
            winningWay,
          },
        },
      });
    },
    openMessage() {
      this.isMessageInputShow = true;
    },
    initialHeaderConfig() {
      this.$bus.$off('header:next');
      this.$bus.$off('header:close');
      this.$apollo.mutate({
        mutation: UPDATE_HEADER_CONFIG,
        variables: {
          headerConfig: {
            title: '棋譜',
            left: 'back',
            middle: 'title',
            rightIcons: this.kifu.isStreaming ? ['Info'] : ['Info', 'Share'],
          },
        },
      });
    },
    openLoginModal() {
      this.$Message.error('請先登入');
      this.$apollo.mutate({
        mutation: UPDATE_LOGIN_MODAL_SHOW,
        variables: {
          isLoginModalShow: true,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#kifu {
  .player-name {
    @apply truncate;
    width: 65px;
  }
  .tab-content {
    @apply flex-1 pt-sm pb-sm pl-md pr-md break-words;
  }
  .kifu-footer {
    @apply w-full flex flex-wrap items-center justify-center flex-nowrap;
  }
  .kifu-footer-item {
    height: 56px;
    @apply w-full pt-sm pb-sm;
  }
  .add-more {
    @apply rounded-6 flex justify-center items-center cursor-pointer;
    width: 30px;
    height: 30px;
    i {
      &:before {
        @apply text-i-white;
      }
    }
  }
  .icon-Delete {
    &:before {
      @apply text-18 text-i-red mr-xs;
    }
  }
  .message-back {
    min-width: 36px;
    height: 36px;
    @apply bg-primary rounded-6;
    i {
      font-size: 20px;
      &:before {
        @apply text-white;
      }
    }
  }
}
</style>
