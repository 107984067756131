var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col relative w-screen h-full",attrs:{"id":"app"}},[_c('Header',{directives:[{name:"show",rawName:"v-show",value:(_vm.routeName != 'login'),expression:"routeName != 'login'"}],attrs:{"title":_vm.headerConfig.title,"left":_vm.headerConfig.left,"left-customized":_vm.headerConfig.leftCustomized,"middle":_vm.headerConfig.middle,"right":_vm.headerConfig.right,"right-icons":_vm.headerConfig.rightIcons,"nextText":_vm.headerConfig.nextText,"nextDisabled":_vm.headerConfig.nextDisabled},on:{"back":_vm.back,"close":_vm.close,"next":_vm.next,"icon-action":_vm.iconAction}}),_c('div',{staticClass:"relative flex-1 page mx-auto h-full w-full overflow-auto",class:[
      {
        'show-app-footer': _vm.isFooterShow,
      },
      ['web', 'pad'].includes(_vm.viewMode) &&
      (!_vm.isCapacitorApp || _vm.device !== 'ios')
        ? 'show-web-footer'
        : 'container' ]},[_c('router-view'),_c('loading',{attrs:{"color":"#5984DE","background-color":"#F7F8FC","loader":"bars","active":_vm.isLoading,"is-full-page":false,"width":50,"height":50},on:{"update:active":function($event){_vm.isLoading=$event}}})],1),(!['web', 'pad'].includes(_vm.viewMode))?_c('Footer',{directives:[{name:"show",rawName:"v-show",value:(_vm.isFooterShow),expression:"isFooterShow"}]}):_c('div',{staticClass:"web-footer text-14 text-center",staticStyle:{"color":"rgb(83 ,81 ,93, 0.5)"}},[_vm._v(" Copyright © 2022 海峰棋院. All rights reserved. ")]),(_vm.isLoginModalShow)?_c('Login',{staticClass:"z-modal absolute top-0 left-0 w-full h-full"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }