<template>
  <div
    class="
      row-item
      flex
      items-center
      rounded-6
      shadow
      bg-white
      text-f-black
      cursor-pointer
    "
    @click="$emit('click')"
  >
    <div class="relative w-full flex justify-center items-center">
      <div
        v-show="tag"
        class="tag text-white rounded-6 inline-flex justify-center"
        :class="tagColor == 'primary' ? 'bg-primary' : 'bg-secondary'"
      >
        {{ tag }}
      </div>
      <i v-show="icon" class="custom-icon" :class="icon"></i>
      <div class="w-full text-left ml-sm text-16">
        <slot></slot>
      </div>
      <slot name="right">
        <i class="icon-LineRight absolute right-0 text-14"></i>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RowItem',
  props: {
    tag: {
      type: String,
    },
    tagColor: {
      type: String,
      default: 'primary',
      validator(value) {
        return ['primary', 'secondary'].includes(value);
      },
    },
    icon: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.row-item {
  height: 50px;
  padding: 0 10px;

  .tag {
    width: 24px;
    height: 24px;
  }

  .custom-icon {
    &:before {
      @apply text-i-dutch-blue;
    }
  }

  .icon-LineRight {
    &:before {
      @apply text-i-gray;
    }
  }
}
</style>
