<template>
  <div class="delete-account mt-lg">
    <BaseButton type="error" @click="showModal = true">
      刪除帳號
    </BaseButton>
    <BaseModal v-model="showModal" title="刪除帳號">
      <p>
        請您提供所需的資料， <br />
        我們將盡快協助您處理帳號刪除的事宜
      </p>
      <div class="mt-md ">
        <BaseButton
          size="small"
          class="mr-md"
          type="custom"
          @click="showModal = false"
        >
          取消
        </BaseButton>
        <BaseButton size="small" type="primary" @click="onClickDeleteAccount">
          前往填寫
        </BaseButton>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import {Browser} from '@capacitor/browser';

export default {
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    async onClickDeleteAccount() {
      await Browser.open({
        url: 'https://forms.gle/rAb8RdUp2YBe22yh8',
        presentationStyle: 'popover',
      });
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.delete-account {
  ::v-deep .custom {
    background: gray;
    color: white;
  }
}
</style>
