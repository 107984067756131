<template>
  <div id="home" class="px-md py-sm h-full flex flex-col">
    <Banner></Banner>
    <div class="relative flex-1 pb-md">
      <div v-show="liveKifus.length > 0">
        <div class="text-18 text-f-black mt-md mb-md">直播中</div>
        <Card
          v-for="kifu in liveKifus"
          :key="kifu.id"
          :kifu="kifu"
          @click="goToKifu(kifu.id)"
        ></Card>
      </div>
      <div v-show="hotKifus.length > 0">
        <div class="text-18 text-f-black mt-md mb-md">最新棋譜</div>
        <Card
          v-for="kifu in hotKifus"
          :key="kifu.id"
          :kifu="kifu"
          @click="goToKifu(kifu.id)"
        ></Card>
      </div>
      <Empty
        v-if="!$apollo.loading"
        v-show="liveKifus.length == 0 && hotKifus.length == 0"
        class="h-full"
        text="目前沒有棋譜"
      ></Empty>
    </div>
  </div>
</template>

<script>
import loadingMixin from '@/mixins/loadingMixin';
import Banner from '@/components/Banner';
import Card from '@/components/Kifu/Card';
import Empty from '@/components/Common/Empty';
import GET_USER from '@/graphql/queries/GetUser.gql';
import GET_PUBLIC_KIFUS from '@/graphql/queries/GetPublicKifus.gql';
import UPDATE_HEADER_CONFIG from '@/graphql/mutations/UpdateHeaderConfig.gql';

export default {
  name: 'Home',
  components: {
    Banner,
    Card,
    Empty,
  },
  mixins: [loadingMixin],
  apollo: {
    user: {
      query: GET_USER,
    },
    liveKifus: {
      query: GET_PUBLIC_KIFUS,
      update: (data) => data?.publicKifus?.kifus || [],
      variables() {
        const filter = {
          limit: 20,
          page: 1,
          isStreaming: true,
          orderBy: [
            {
              column: 'gameStartedAt',
              reverse: true,
            },
          ],
        };
        return {
          filter,
          isLogin: !!this.user,
        };
      },
    },
    hotKifus: {
      query: GET_PUBLIC_KIFUS,
      update: (data) => data?.publicKifus?.kifus || [],
      variables() {
        const filter = {
          limit: 20,
          page: 1,
          isPopular: true,
          orderBy: [
            {
              column: 'gameStartedAt',
              reverse: true,
            },
          ],
        };
        return {
          filter,
          isLogin: !!this.user,
        };
      },
    },
  },
  data() {
    return {
      liveKifus: [],
      hotKifus: [],
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$apollo.mutate({
        mutation: UPDATE_HEADER_CONFIG,
        variables: {
          headerConfig: {
            left: 'logo',
            right: 'search',
          },
        },
      });
    });
  },
  methods: {
    goToKifu(id) {
      this.$router.push({name: 'kifu', params: {id}});
    },
  },
};
</script>

<style lang="scss" scoped>
#home {
}
</style>
