<template>
  <div id="kifus" class="relative p-md pb-md pt-0 h-full overflow-scroll">
    <Card
      v-for="kifu in kifus"
      class="mt-lg"
      :key="kifu.id"
      :kifu="kifu"
      @click="goToKifu(kifu.id)"
    ></Card>
    <Empty
      v-if="!$apollo.loading"
      v-show="kifus.length == 0"
      class="h-full"
      text="目前沒有此分類的棋譜"
    ></Empty>
    <infinite-loading
      v-if="kifus.length > 0 && hasMore"
      @infinite="infiniteHandler"
    ></infinite-loading>
  </div>
</template>

<script>
import loadingMixin from '@/mixins/loadingMixin';
import Card from '@/components/Kifu/Card';
import Empty from '@/components/Common/Empty';
import GET_USER from '@/graphql/queries/GetUser.gql';
import GET_PUBLIC_KIFUS from '@/graphql/queries/GetPublicKifus.gql';
import UPDATE_HEADER_CONFIG from '@/graphql/mutations/UpdateHeaderConfig.gql';

export default {
  name: 'Kifus',
  components: {
    Card,
    Empty,
  },
  mixins: [loadingMixin],
  apollo: {
    user: {
      query: GET_USER,
    },
    kifus: {
      query: GET_PUBLIC_KIFUS,
      update: (data) => data?.publicKifus?.kifus || [],
      variables() {
        return {
          filter: Object.assign(
            {
              page: 1,
            },
            this.filter,
          ),
          isLogin: !!this.user,
        };
      },
    },
  },
  data() {
    return {
      kifus: [],
      page: 1,
      hasMore: true,
    };
  },
  computed: {
    filter() {
      const filter = {
        limit: 20,
        isPublished: true,
        orderBy: [
          {
            column: 'seq',
            reverse: false,
          },
        ],
      };
      if (this.$route.query.orderBy != null) {
        const reverse =
          this.$route.query.reverse != null
            ? Boolean(this.$route.query.reverse)
            : true;
        filter.orderBy.push({column: this.$route.query.orderBy, reverse});
      } else if (
        this.$route.query.orderBy == null &&
        this.$route.query.reverse == null
      ) {
        filter.orderBy.push({column: 'gameStartedAt', reverse: true});
      }
      if (this.$route.query.search != null) {
        filter.search = this.$route.query.search;
      }
      if (this.$route.query.tournamentName != null) {
        filter.tournamentName = this.$route.query.tournamentName;
      }
      if (this.$route.query.playerName != null) {
        filter.playerName = this.$route.query.playerName;
      }
      if (this.$route.query.tournamentId != null) {
        filter.tournamentId = Number(this.$route.query.tournamentId);
      }
      if (
        this.$route.query.isCommentedByCommentator == 'true' ||
        this.$route.query.isCommentedByCommentator === true
      ) {
        filter.isCommentedByCommentator = true;
      }
      if (
        this.$route.query.isPopular == 'true' ||
        this.$route.query.isPopular === true
      ) {
        filter.isPopular = true;
      }
      if (this.$route.query.createdAfter != null) {
        filter.createdAfter = new Date(this.$route.query.createdAfter);
      }
      if (this.$route.query.createdBefore != null) {
        filter.createdBefore = new Date(this.$route.query.createdBefore);
      }
      if (this.$route.query.gameStartedAfter != null) {
        filter.gameStartedAfter = new Date(this.$route.query.gameStartedAfter);
      }
      if (this.$route.query.gameStartedBefore != null) {
        filter.gameStartedBefore = new Date(
          this.$route.query.gameStartedBefore,
        );
      }
      return filter;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$apollo.mutate({
        mutation: UPDATE_HEADER_CONFIG,
        variables: {
          headerConfig: {
            title: '棋譜列表',
            left: 'back',
            middle: 'title',
          },
        },
      });
    });
  },
  methods: {
    goToKifu(id) {
      this.$router.push({name: 'kifu', params: {id}});
    },
    infiniteHandler() {
      this.page += 1;
      this.hasMore = false;
      this.$apollo.queries.kifus.fetchMore({
        variables: {
          filter: Object.assign(
            {
              page: this.page,
            },
            this.filter,
          ),
        },
        updateQuery: (previousResult, {fetchMoreResult}) => {
          const currentKifus = previousResult?.publicKifus?.kifus || [];
          const newKifus = fetchMoreResult?.publicKifus?.kifus || [];

          const count = fetchMoreResult?.publicKifus?.count;
          if (currentKifus.length + newKifus.length < count) {
            this.hasMore = true;
          }

          return {
            publicKifus: {
              __typename: previousResult.publicKifus.__typename,
              kifus: [...currentKifus, ...newKifus],
              count,
            },
          };
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#kifus {
}
</style>
