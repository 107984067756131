<template>
  <div class="footer">
    <div
      v-for="item in items"
      class="
        item
        flex flex-col
        items-center
        justify-center
        text-sm
        cursor-pointer
      "
      :key="item.name"
      :class="{active: routeName == item.name}"
      v-auth="item.name == 'record-kifu'"
      @auth="go(item.name)"
    >
      <i :class="item.icon" class="text-xl"></i>
      <span>{{ item.text }}</span>
    </div>

    <BasePopMenu
      class="text-center"
      :show="isChooseRecordKifuWay"
      @close="isChooseRecordKifuWay = false"
    >
      <div class="text-f-black text-18 font-bold pt-sm pb-sm">
        請選擇記譜類型
      </div>
      <BaseLine />
      <div>
        <BaseButton
          type="primary"
          class="mt-md"
          @click="go('record-kifu', {type: 'public'})"
          >公開棋譜</BaseButton
        >
        <BaseButton
          type="primary"
          class="mt-md mb-md"
          @click="go('record-kifu', {type: 'private'})"
          >個人記譜</BaseButton
        >
      </div>
    </BasePopMenu>
  </div>
</template>

<script>
import GET_USER from '@/graphql/queries/GetUser.gql';

export default {
  name: 'Footer',
  apollo: {
    user: {
      query: GET_USER,
    },
  },
  data() {
    return {
      user: null,
      items: [
        {name: 'home', text: '首頁', icon: 'icon-Home2'},
        {name: 'kifu-category', text: '棋譜', icon: 'icon-HfKifu'},
        {name: 'record-kifu', text: '記譜', icon: 'icon-Edit'},
        {name: 'newses', text: '新聞', icon: 'icon-News'},
        {name: 'more', text: '更多', icon: 'icon-More'},
      ],
      isChooseRecordKifuWay: false,
    };
  },
  computed: {
    permissions() {
      return this.user?.role?.permissions || [];
    },
    routeName() {
      return this.$route.name || '';
    },
  },
  methods: {
    go(name, params) {
      if (name == 'record-kifu') {
        if (params) {
          this.isChooseRecordKifuWay = false;
          this.$router.push({name, params});
        } else if (
          this.permissions.some((d) => d.code == 'SUBMIT_PUBLIC_KIFU')
        ) {
          this.isChooseRecordKifuWay = true;
        } else {
          this.isChooseRecordKifuWay = false;
          this.$router.push({
            name,
            params: {
              type: 'private',
            },
          });
        }
        return;
      }
      this.$router.push({name});
    },
  },
};
</script>

<style scoped lang="scss">
.footer {
  height: 54px;
  @apply w-full grid grid-cols-5 border-t border-b border-solid border-black-10;

  .item {
    color: rgba(#504e5a, 0.5);

    i {
      &:before {
        color: rgba(#504e5a, 0.5);
      }
    }

    &.active {
      @apply text-primary;

      i:before {
        @apply text-primary;
      }
    }
  }
}
</style>
