<template>
  <div class="kifu-controll relative">
    <div
      v-show="isMenuShow && tab !== 'message'"
      class="absolute w-full bg-i-white z-modal"
      :style="showStreamAction ? {top: '-104px'} : {top: '-52px'}"
    >
      <BaseLine />
      <div
        v-show="showStreamAction"
        class="flex justify-center items-center pl-md pr-md"
        style="height: 52px"
      >
        <BaseButton
          type="plain"
          size="small"
          style="width: 144px"
          @click="$emit('undo')"
          >撤回棋子</BaseButton
        >
        <BaseButton
          type="error"
          size="small"
          class="ml-md"
          style="width: 144px"
          @click="isEndStreamModalShow = true"
          >結束直播</BaseButton
        >
      </div>
      <div
        class="flex justify-center items-center pl-md pr-md"
        style="height: 52px"
      >
        <BaseButton
          type="plain"
          size="auto"
          style="width: 91px"
          @click="$emit('update-coordinate', !coordinate)"
        >
          {{ coordinate ? '隱藏座標' : '顯示座標' }}</BaseButton
        >
        <BaseButton
          type="plain"
          size="auto"
          class="ml-md"
          style="width: 91px"
          @click="$emit('toggle-step')"
          >顯示手數</BaseButton
        >
        <BaseButton
          v-show="!variationShow"
          type="warning"
          size="auto"
          class="ml-md"
          style="width: 91px"
          @click="$emit('update-test-play', !testPlay)"
        >
          {{ testPlay ? '離開試下' : '進入試下' }}</BaseButton
        >
        <BaseButton
          v-show="variationShow"
          type="warning"
          size="auto"
          class="ml-md text-14"
          style="width: 91px"
          @click="$emit('leave-variation')"
          >離開變化圖</BaseButton
        >
      </div>
      <BaseLine />
    </div>
    <div class="pt-xs pb-xs pl-md pr-md flex items-center">
      <button class="flex items-center justify-center">
        <i class="icon-Rewind" @click="$emit('click', 'prev')" />
      </button>
      <button class="flex items-center justify-center ml-sm">
        <i class="icon-Play" @click="$emit('click', 'next')" />
      </button>
      <div class="flex-1 flex items-center pl-lg pr-lg">
        <VueSlider
          :value="value"
          :dotSize="30"
          :height="10"
          :max="max"
          @change="$emit('input', $event)"
          style="width: 100%"
        ></VueSlider>
      </div>
      <button
        v-if="tab !== 'message'"
        @click="isMenuShow = !isMenuShow"
        class="flex items-center justify-center"
      >
        <i class="icon-More" />
      </button>
      <button
        v-else
        @click="onClickMessage"
        class="flex items-center justify-center"
      >
        <i class="icon-Comment" />
      </button>
    </div>

    <BaseModal v-model="isEndStreamModalShow" title="勝負結果">
      <div class="text-16 mb-md text-center">結束直播前請輸入勝負結果</div>
      <BaseInput
        v-model="winningWay"
        class="mb-lg"
        type="text"
        placeholder="請輸入結果"
      ></BaseInput>

      <BaseButton
        class="w-full"
        type="primary"
        @click="
          isEndStreamModalShow = false;
          $emit('end-stream', winningWay);
        "
      >
        確定
      </BaseButton>
    </BaseModal>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component';
import '@/assets/scss/slider.scss';

export default {
  name: 'KifuControll',
  components: {
    VueSlider,
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    showStreamAction: {
      type: Boolean,
      default: false,
    },
    coordinate: {
      type: Boolean,
      default: true,
    },
    testPlay: {
      type: Boolean,
      default: false,
    },
    variationShow: {
      type: Boolean,
      default: false,
    },
    tab: {
      type: String,
      default: 'comment',
    },
  },
  data() {
    return {
      isMenuShow: false,
      isEndStreamModalShow: false,
      winningWay: '',
    };
  },
  methods: {
    onClickMessage() {
      this.$emit('open-message');
    },
  },
};
</script>

<style scoped lang="scss">
.kifu-controll {
  button {
    width: 36px;
    height: 36px;
    @apply bg-primary rounded-6;
  }
  i {
    font-size: 20px;
    &:before {
      @apply text-white;
    }
  }
}
</style>
