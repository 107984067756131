import {Device} from '@capacitor/device';
import UPDATE_HEADER_CONFIG from '@/graphql/mutations/UpdateHeaderConfig.gql';
import {getMoveSgf} from 'goer-utils/function/sgf';
import {Keyboard} from '@capacitor/keyboard';

const mixinObject = {
  data() {
    return {
      kifuMode: 'private', // private: 私人棋譜、live: 直播棋譜
      id: Number(this.$route.params.id),
      isKifuDetailShow: false, // 是否顯示棋譜詳細資訊
      isTestPlay: false, // 是否試下狀態
      isVariationShow: false, // 是否變化圖狀態
      isCoordinatesShow: true, // 是否顯示座標
      isLoading: false, // 是否API發送loading中
      tab: 'comment',
      action: 'view',
      step: 0,
      completeStep: 0,
      testPlayStep: 0,
      testPlayCompleteStep: 0,
      capCount: 0,
      turnColor: 'black',
      comments: [],
      variationStep: null, // 輸入變化時的手數
      selectedComment: null, // 選擇的解說
      selectedVariation: null, // 選擇的變化圖
      variations: [],
      commentContent: '', // 解說文字
      isMessageInputShow: false,
    };
  },
  computed: {
    showVariations() {
      // 新增變化圖時會平面化，導致手數變為0
      if (this.action == 'input') {
        return this.variations.filter(
          (variation) => variation.step == this.variationStep,
        );
      }
      return this.variations.filter((variation) => variation.step == this.step);
    },
    selectedVariationSerial() {
      if (!this.selectedVariation) {
        return null;
      }
      return (
        this.showVariations.findIndex(
          (variation) => variation == this.selectedVariation,
        ) + 1
      );
    },
    showStep: {
      get() {
        return this.isTestPlay ? this.testPlayStep : this.step;
      },
      set(value) {
        if (this.isTestPlay) {
          this.testPlayStep = value;
        } else {
          this.step = value;
        }
      },
    },
    showCompleteStep() {
      return this.isTestPlay ? this.testPlayCompleteStep : this.completeStep;
    },
    isKifuControlShow() {
      return (
        (this.tab == 'comment' && ['single', 'view'].includes(this.action)) ||
        (this.tab == 'variation' &&
          ['view', 'input', 'single', 'edit'].includes(this.action)) ||
        this.tab === 'message'
      );
    },
    isOnlyBoard() {
      return this.isKeyboardShow && ['input', 'edit'].includes(this.action);
    },
  },
  watch: {
    // isOnlyBoard(value) {
    //   // if(value) {
    //     this.$nextTick(() => {
    //       this.wgoHelper.player.updateDimensions();
    //     });
    //   // }
    // },
    testPlayStep(value) {
      if (this.tab == 'variation' && ['input', 'edit'].includes(this.action)) {
        const nextDisabled = value == 0;
        this.$apollo.mutate({
          mutation: UPDATE_HEADER_CONFIG,
          variables: {
            headerConfig: {
              nextDisabled,
            },
            update: true,
          },
        });
      }
    },
    commentContent(value) {
      // 新增、編輯解說時，一定要有內容才能發佈
      if (this.tab == 'comment' && ['input', 'edit'].includes(this.action)) {
        this.$apollo.mutate({
          mutation: UPDATE_HEADER_CONFIG,
          variables: {
            headerConfig: {
              nextDisabled: !value,
            },
            update: true,
          },
        });
      }
      // 編輯變化圖時只要有改動，就可以發佈
      else if (this.tab == 'variation' && ['edit'].includes(this.action)) {
        this.$apollo.mutate({
          mutation: UPDATE_HEADER_CONFIG,
          variables: {
            headerConfig: {
              nextDisabled: false,
            },
            update: true,
          },
        });
      }
    },
    action(value) {
      if (value == 'view') {
        this.initialHeaderConfig();
      } else {
        this.$bus.$off('header:next');
        this.$bus.$off('header:close');
        if (value == 'input') {
          // 新增解說
          if (this.tab == 'comment') {
            this.$apollo.mutate({
              mutation: UPDATE_HEADER_CONFIG,
              variables: {
                headerConfig: {
                  title: '新增解說',
                  left: 'close',
                  middle: 'title',
                  right: 'next',
                  nextText: '發佈',
                  nextDisabled: true,
                },
              },
            });
            this.$bus.$on('header:next', async () => {
              await this.addComment(true);
            });
          } else if (this.tab == 'variation') {
            this.$apollo.mutate({
              mutation: UPDATE_HEADER_CONFIG,
              variables: {
                headerConfig: {
                  title: '新增變化圖',
                  left: 'close',
                  middle: 'title',
                  right: 'next',
                  nextText: '發佈',
                  nextDisabled: true,
                },
              },
            });
            this.$bus.$on('header:next', async () => {
              await this.addVariation();
            });
          }
        } else if (value == 'edit') {
          // 編輯解說
          if (this.tab == 'comment') {
            this.$apollo.mutate({
              mutation: UPDATE_HEADER_CONFIG,
              variables: {
                headerConfig: {
                  title: '編輯解說',
                  left: 'close',
                  middle: 'title',
                  right: 'next',
                  nextText: '發佈',
                  nextDisabled: true,
                },
              },
            });
            this.$bus.$on('header:next', async () => {
              await this.updateComment();
            });
          }
          // 編輯變化圖
          else if (this.tab == 'variation') {
            this.$apollo.mutate({
              mutation: UPDATE_HEADER_CONFIG,
              variables: {
                headerConfig: {
                  title: '編輯變化圖',
                  left: 'close',
                  middle: 'title',
                  right: 'next',
                  nextText: '發佈',
                  nextDisabled: true,
                },
              },
            });
            this.$bus.$on('header:next', async () => {
              await this.updateVariation();
            });
          }
        }
        // 左上角打叉
        this.$bus.$on('header:close', () => {
          this.reset();
        });
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.initialHeaderConfig();
    });
  },
  beforeDestroy() {
    this.$bus.$off('header:next');
    this.$bus.$off('header:close');
  },
  methods: {
    showKifuDetail() {
      this.isKifuDetailShow = true;
    },
    closeKifuDetail() {
      this.isKifuDetailShow = false;
      this.initialHeaderConfig();
    },
    updateStep(step) {
      this.step = step;
      this.wgoHelper.goto(step);
    },
    reset() {
      this.action = 'view';

      this.selectedComment = null;
      this.commentContent = '';

      this.testPlayStep = 0;
      this.testPlayCompleteStep = 0;
      this.isVariationShow = false;
      this.isTestPlay = false;
      this.variationStep = null;
      this.wgoHelper.endTestPlay();
      this.selectedVariation = null;

      if (this.originalShowStepType) {
        this.wgoHelper.showStep(this.originalShowStepType);
        this.originalShowStepType = null;
      }
    },
    toggleCoordinates() {
      this.isCoordinatesShow = this.wgoHelper.toggleCoordinates();
    },
    toggleStep() {
      this.wgoHelper.toggleStep();
    },
    toggleTestPlay() {
      if (!this.isTestPlay) {
        this.isTestPlay = true;
        this.wgoHelper.startTestPlay();
      } else {
        this.isTestPlay = false;
        this.wgoHelper.endTestPlay();
      }
    },
    async hideKeyboard() {
      const deviceInfo = await Device.getInfo();
      if (deviceInfo.platform != 'web') {
        Keyboard.hide();
      }
    },
    /* 解說相關 */
    async addComment(isCreatedByCommentator) {
      if (!this.commentContent || this.isLoading) {
        return;
      }
      this.isLoading = true;
      const comment = {
        step: this.step,
        content: this.commentContent,
      };
      if (this.kifuMode == 'live') {
        comment.isCreatedByCommentator = isCreatedByCommentator;
      }
      try {
        const result = await this.$apollo.mutate({
          mutation: this.updateMutation,
          variables: {
            input: {
              id: this.id,
              createCommentsInput: [comment],
            },
          },
        });

        if (this.kifuMode == 'private') {
          this.comments = result?.data?.[this.updateResponseName]?.comments;
        }
        const message = isCreatedByCommentator
          ? '新增解說成功'
          : '新增留言成功';
        this.$Message.success(message);
      } catch (error) {
        if (error.message === 'GraphQL error: The user is banned') {
          this.$Message.error('您因多次發言不當，已被禁言');
          this.$apollo.queries.user.refetch();
        }
      }
      this.reset();
      this.isLoading = false;
    },
    editComment(comment) {
      this.selectedComment = comment;
      this.commentContent = comment.content;
      this.action = 'edit';
    },
    async updateComment() {
      const result = await this.$apollo.mutate({
        mutation: this.updateMutation,
        variables: {
          input: {
            id: this.id,
            updateCommentsInput: [
              {
                id: this.selectedComment.id,
                content: this.commentContent,
              },
            ],
          },
        },
      });
      if (this.kifuMode == 'private') {
        this.comments = result?.data?.[this.updateResponseName]?.comments;
      }
      this.$Message.success('編輯解說成功');
      this.reset();
    },
    async deleteComment() {
      const result = await this.$apollo.mutate({
        mutation: this.updateMutation,
        variables: {
          input: {
            id: this.id,
            deleteCommentsInput: [
              {
                id: this.selectedComment.id,
              },
            ],
          },
        },
      });
      if (this.kifuMode == 'private') {
        this.comments = result?.data?.[this.updateResponseName]?.comments;
      }
      this.$Message.success('刪除解說成功');
      this.reset();
    },
    /* 變化圖相關 */
    wantInputVariation() {
      this.action = 'input';
      this.isTestPlay = true;
      this.variationStep = this.wgoHelper.step;
      this.wgoHelper.startTestPlay();
      this.originalShowStepType = this.wgoHelper.showStepType;
      this.wgoHelper.showStep('all');
    },
    async addVariation() {
      const variation = {
        step: this.variationStep,
        comment: this.commentContent,
        sgf: getMoveSgf(this.wgoHelper.sgf),
      };
      const result = await this.$apollo.mutate({
        mutation: this.updateMutation,
        variables: {
          input: {
            id: this.id,
            createVariationsInput: [variation],
          },
        },
      });
      if (this.kifuMode == 'private') {
        this.variations = result?.data?.[this.updateResponseName]?.variations;
      }
      this.$Message.success('新增變化圖成功');
      this.reset();
    },
    async editVariation(variation) {
      this.action = 'edit';
      this.isTestPlay = true;
      this.isVariationShow = true;
      this.selectedVariation = variation;
      this.commentContent = variation.comment;
      this.wgoHelper.startTestPlay();
      this.wgoHelper.gotoVariantion(variation.sgf);
      this.originalShowStepType = this.wgoHelper.showStepType;
      this.wgoHelper.showStep('all');
    },
    async viewVariation(variation, serial) {
      await this.$apollo.mutate({
        mutation: UPDATE_HEADER_CONFIG,
        variables: {
          headerConfig: {
            title: `變化圖${variation.step}-${serial}`,
            left: 'close',
            middle: 'title',
          },
        },
      });
      this.action = 'single';
      this.isTestPlay = true;
      this.isVariationShow = true;
      this.selectedVariation = variation;
      this.wgoHelper.startTestPlay();
      this.wgoHelper.gotoVariantion(variation.sgf);
      this.originalShowStepType = this.wgoHelper.showStepType;
      this.wgoHelper.showStep('all');
    },
    async updateVariation() {
      const result = await this.$apollo.mutate({
        mutation: this.updateMutation,
        variables: {
          input: {
            id: this.id,
            updateVariationsInput: [
              {
                id: this.selectedVariation.id,
                sgf: getMoveSgf(this.wgoHelper.sgf),
                comment: this.commentContent,
              },
            ],
          },
        },
      });
      if (this.kifuMode == 'private') {
        this.variations = result?.data?.[this.updateResponseName]?.variations;
      }
      this.$Message.success('編輯變化圖成功');
      this.reset();
    },
    async deleteVariation() {
      const result = await this.$apollo.mutate({
        mutation: this.updateMutation,
        variables: {
          input: {
            id: this.id,
            deleteVariationsInput: [
              {
                id: this.selectedVariation.id,
              },
            ],
          },
        },
      });
      if (this.kifuMode == 'private') {
        this.variations = result?.data?.[this.updateResponseName]?.variations;
      }
      this.$Message.success('刪除變化圖成功');
      this.reset();
    },
  },
};

export default mixinObject;
