import {Howl} from 'howler';

class SoundService {
  constructor() {
    const resourcesUrlMap = {
      click: require('@/assets/sound/click.mp3'),
      play: require('@/assets/sound/play.mp3'),
    };
    this.resourcesMap = {};
    this.resourcesState = {
      click: true,
      play: true,
    };

    /**
     * 載入聲音資源
     */
    Object.keys(resourcesUrlMap).forEach((key) => {
      const url = resourcesUrlMap[key];
      const sound = new Howl({
        src: [url],
        html5: true, // 緩存音效檔
      });
      this.resourcesMap[key] = sound;
    });
  }

  /**
   * 播放音效
   * @param {String} soundName 音效名稱
   * @param {Number} volume 音量大小
   * @returns {String} 音效描述文字
   */
  playSound(soundName, volume = 0.5) {
    if (this.resourcesState[soundName] === false) {
      return false;
    }
    const sound = this.resourcesMap[soundName];
    sound.volume = volume;
    sound.play();
    return true;
  }

  disable(soundName) {
    this.resourcesState[soundName] = false;
  }

  able(soundName) {
    this.resourcesState[soundName] = true;
  }

  /**
   * 停止音效
   * @param {String} soundName 音效名稱
   */
  stopSound(soundName) {
    const sound = this.resourcesMap[soundName];
    sound.pause();
    sound.currentTime = 0;
  }
}

export default new SoundService();
