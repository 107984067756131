<template>
  <div
    class="
      card
      p-sm
      flex flex-col
      items-center
      rounded-6
      shadow
      bg-white
      text-f-black
      cursor-pointer
    "
    @click="$emit('click')"
  >
    <div class="w-full flex justify-between items-center">
      <span class="text-i-dutch-blue">
        <i class="icon-News" />
        News
      </span>
      <span class="text-12 text-f-black-50">{{ dateText }}</span>
    </div>
    <div class="w-full truncate">
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    dateText: {
      type: String,
    },
    title: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  height: 61px;

  i {
    &:before {
      @apply text-i-dutch-blue;
    }
  }
}
</style>
