<template>
  <div id="newses" class="pl-md pr-md">
    <Card
      class="mt-sm"
      v-for="news in newses"
      :key="news.id"
      :dateText="news.dateText"
      :title="news.title"
      @click="go(news.id)"
    ></Card>
    <infinite-loading
      v-if="newses.length > 0 && hasMore"
      @infinite="infiniteHandler"
    ></infinite-loading>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import axios from 'axios';
import UPDATE_HEADER_CONFIG from '@/graphql/mutations/UpdateHeaderConfig.gql';
import Card from '@/components/News/Card';

export default {
  name: 'Newses',
  components: {
    Card,
  },
  data() {
    return {
      newses: [],
      hasMore: true,
      page: 0,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$apollo.mutate({
        mutation: UPDATE_HEADER_CONFIG,
        variables: {
          headerConfig: {
            title: '新聞',
            middle: 'title',
          },
        },
      });
    });
  },
  async created() {
    this.newses = await this.getNewses();
  },
  methods: {
    go(id) {
      this.$router.push({
        name: 'news',
        params: {
          id,
        },
      });
    },
    async getNewses() {
      const {data} = await axios.get(
        process.env.VUE_APP_NEWS_API + '/news/api_get_news_list/' + this.page,
      );
      if (data?.data?.list) {
        return data.data.list.map((news) => {
          return {
            id: news.id,
            dateText: dayjs(news.released_on * 1000).format('YYYY/MM/DD'),
            title: news.name.replace(/\d{4}\/\d{2}\/\d{2}\s?/, ''),
          };
        });
      }
      return [];
    },
    async infiniteHandler() {
      this.page += 1;
      this.hasMore = false;
      const moreNewses = await this.getNewses();
      if (moreNewses.length > 0) {
        this.newses.push(...moreNewses);
        this.hasMore = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#newses {
}
</style>
