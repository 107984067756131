<template>
  <div class="header relative w-full flex items-center bg-primary">
    <div class="flex-1 pl-md">
      <img v-show="left == 'logo'" :src="Logo" style="height: 30px" />
      <i class="icon-LineLeft" v-show="left == 'back'" @click="goBack" />
      <i class="icon-Close" v-show="left == 'close'" @click="close" />
    </div>
    <div class="flex-1 text-center">
      <span
        v-show="middle === 'title'"
        class="text-i-white text-18 font-bold"
        >{{ title }}</span
      >
      <img
        v-show="middle == 'logo'"
        :src="Logo"
        style="height: 24px; margin:0 auto;"
      />
    </div>
    <div class="flex-1 text-right pr-md text-24">
      <!-- <i v-show="right == 'search'" class="icon-Search"></i> -->
      <span
        v-show="right == 'next'"
        class="text-16 cursor-pointer"
        :class="nextDisabled ? 'text-white-20' : 'text-white'"
        @click="next"
        >{{ nextText }}</span
      >
      <template v-if="rightIcons.length !== 0">
        <i
          v-for="(icon, index) in rightIcons"
          :key="`${icon}${index}`"
          :class="[
            `icon-${icon}`,
            rightIcons.length !== index + 1 ? 'mr-md' : '',
          ]"
          @click="iconAction(icon)"
        ></i
      ></template>
    </div>
  </div>
</template>

<script>
import Logo from '@/assets/img/logo.svg';

export default {
  name: 'Header',
  props: {
    title: {
      type: String,
    },
    left: {
      type: String,
      validator(value) {
        return ['', 'logo', 'back', 'close'].includes(value);
      },
    },
    leftCustomized: {
      type: Boolean,
      default: false,
    },
    middle: {
      type: String,
      validator(value) {
        return ['', 'title', 'logo'].includes(value);
      },
    },
    right: {
      type: String,
      validator(value) {
        return ['', 'search', 'next'].includes(value);
      },
    },
    rightIcons: {
      type: Array,
      default() {
        return [];
      },
    },
    nextText: {
      type: String,
      default: '下一步',
    },
    nextDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      Logo,
    };
  },
  methods: {
    goBack() {
      if (this.leftCustomized) {
        this.$emit('back');
      } else {
        window.history.length > 2
          ? this.$router.go(-1)
          : this.$router.push({name: 'home'});
      }
    },
    next() {
      if (!this.nextDisabled) {
        this.$emit('next');
      }
    },
    close() {
      this.$emit('close');
    },
    iconAction(actionName) {
      this.$emit('icon-action', actionName);
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  height: 44px;

  i {
    @apply cursor-pointer;
    &:before {
      @apply text-i-white;
    }
  }
}
</style>
