<template>
  <div id="news" class="p-md">
    <div class="text-18 font-bold">
      {{ title }}
    </div>
    <BaseLine class="mt-md mb-md"></BaseLine>
    <div v-html="content"></div>
  </div>
</template>

<script>
import axios from 'axios';
import UPDATE_HEADER_CONFIG from '@/graphql/mutations/UpdateHeaderConfig.gql';

export default {
  name: 'News',
  data() {
    return {
      id: this.$route.params.id,
      title: '',
      content: '',
    };
  },
  created() {
    this.getNews();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$apollo.mutate({
        mutation: UPDATE_HEADER_CONFIG,
        variables: {
          headerConfig: {
            left: 'back',
            title: '新聞',
            middle: 'title',
          },
        },
      });
    });
  },
  methods: {
    async getNews() {
      const {data} = await axios.get(
        process.env.VUE_APP_NEWS_API + '/news/api_get_news/' + this.id,
      );
      if (data?.data) {
        this.title = data.data.name;
        this.content = data.data.content;
      }

      await this.$nextTick();

      const width = window.innerWidth - 30;
      const iframes = document.querySelectorAll('iframe');
      for (const iframe of iframes) {
        iframe.width = width;
        iframe.height = (width / 4) * 3;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#news {
  iframe {
    max-width: 90vw;
  }
}
</style>
