<template>
  <div id="record-kifu" class="relative h-full">
    <div v-show="!isKifuFormShow" class="flex flex-col h-full">
      <div class="bg-background p-sm flex-1 flex items-center">
        <div
          id="player"
          class="bg-board1 rounded-10 w-full mt-13"
          :class="[boardClass]"
        ></div>
      </div>
      <BaseLine />
      <div class="bg-white w-full flex items-center" style="height: 132px">
        <KifuControll
          @click="onBoardControlerClick"
          class="w-full"
        ></KifuControll>
      </div>
    </div>

    <KifuFormPrivate
      v-if="type == 'private' && isKifuFormShow"
      class="absolute top-0 left-0"
      mode="create"
      :sgf="sgf"
      @close="closeForm"
    ></KifuFormPrivate>
    <KifuFormPublic
      v-if="type == 'public' && isKifuFormShow"
      class="absolute top-0 left-0"
      mode="edit"
      :sgf="sgf"
      @close="closeForm"
    ></KifuFormPublic>
  </div>
</template>

<script>
import WgoHelper from 'wgo-helper';
import boardMixin from '@/mixins/boardMixin';
import KifuControll from '@/components/RecordKifu/KifuControll';
import KifuFormPrivate from '@/components/Kifu/KifuFormPrivate';
import KifuFormPublic from '@/components/Kifu/KifuFormPublic';
import GET_SETTING from '@/graphql/queries/GetSetting.gql';
import UPDATE_HEADER_CONFIG from '@/graphql/mutations/UpdateHeaderConfig.gql';

export default {
  components: {
    KifuControll,
    KifuFormPrivate,
    KifuFormPublic,
  },
  mixins: [boardMixin],
  apollo: {
    setting: {
      query: GET_SETTING,
    },
  },
  data() {
    return {
      isKifuFormShow: false,
      sgf: '',
    };
  },
  computed: {
    type() {
      return this.$route.params.type;
    },
    boardClass() {
      return this.setting?.boardColor === '2' ? 'bg-board2' : 'bg-board1';
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.initialHeader();
    });
  },
  mounted() {
    this.initialPlayer({
      boardSize: 19,
    });
  },
  beforeDestroy() {
    this.$bus.$off('header:next');
  },
  methods: {
    initialHeader() {
      this.$apollo.mutate({
        mutation: UPDATE_HEADER_CONFIG,
        variables: {
          headerConfig: {
            title: '記譜',
            left: 'back',
            middle: 'title',
            right: 'next',
            nextText: '下一步',
          },
        },
      });
      this.$nextTick(() => {
        this.$bus.$on('header:next', () => {
          if (!this.isKifuFormShow) {
            this.isKifuFormShow = true;
            this.sgf = this.wgoHelper.sgf;
            this.$apollo.mutate({
              mutation: UPDATE_HEADER_CONFIG,
              variables: {
                headerConfig: {
                  nextText: '提交',
                  nextDisabled: true,
                },
                update: true,
              },
            });
          }
        });
      });
    },
    initialPlayer(gameData) {
      const sgf = gameData.sgf || `(;CA[big5]SZ[${gameData.boardSize}])`;
      const theme = {stoneHandler: 'GLOW'};
      this.wgoHelper = new WgoHelper('game', 'player', sgf, {theme});

      this.wgoHelper.player.setCoordinates(true);
      this.wgoHelper.on('play', () => {
        this.$playSound('play');
      });
      this.wgoHelper.on('update', () => {
        this.capCount = this.wgoHelper.captureCount;
        this.turnColor = this.wgoHelper.turnColor;
        this.step = this.wgoHelper.step;
      });
      this.capCount = this.wgoHelper.captureCount;
      this.turnColor = this.wgoHelper.turnColor;
      this.step = this.wgoHelper.step;
      this.completeStep = this.wgoHelper.completeStep;
    },
    closeForm() {
      this.isKifuFormShow = false;
      this.initialHeader();
    },
  },
};
</script>

<style lang="scss" scoped>
#record-kifu {
}
</style>
