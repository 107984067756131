<template>
  <div id="kifu-category" class="p-md">
    <div class="kifu-type grid grid-flow-row grid-cols-3 grid-rows-2 gap-sm">
      <Block
        text="最新棋譜"
        icon="icon-New"
        class="row-span-1 col-span-1"
        @click="goToKifus({orderBy: 'gameStartedAt', reverse: true})"
      ></Block>
      <Block
        text="解說棋譜"
        icon="icon-Comment"
        class="row-span-1 col-span-1"
        @click="goToKifus({isCommentedByCommentator: true})"
      ></Block>
      <Block
        text="最新棋譜"
        icon="icon-Hot"
        class="row-span-2 col-span-1"
        @click="goToKifus({isPopular: true})"
      ></Block>
      <Block
        text="棋譜查詢"
        icon="icon-Search"
        class="row-span-1 col-span-1"
        @click="goToSearch"
      ></Block>
      <Block
        text="我的收藏"
        icon="icon-Favorite"
        class="row-span-1 col-span-1"
        v-auth
        @auth="goToMyKifu({isCollected: true})"
      ></Block>
    </div>
    <BaseLine class="mt-md mb-md" />
    <div>
      <RowItem
        v-for="tag in tags"
        :key="tag.id"
        :tag="tag.name[0]"
        @click="goToTournament(tag.id)"
        class="mb-sm"
      >
        {{ tag.name }}
      </RowItem>
      <RowItem
        v-show="!!user"
        class="mb-sm"
        tag="我"
        tagColor="secondary"
        @click="goToMyKifu()"
        >我的棋譜</RowItem
      >
    </div>
  </div>
</template>

<script>
import loadingMixin from '@/mixins/loadingMixin';
import Block from '@/components/KifuCategory/Block';
import GET_TAGS from '@/graphql/queries/GetTags.gql';
import GET_USER from '@/graphql/queries/GetUser.gql';
import UPDATE_HEADER_CONFIG from '@/graphql/mutations/UpdateHeaderConfig.gql';

export default {
  name: 'KifuCategory',
  components: {
    Block,
  },
  mixins: [loadingMixin],
  apollo: {
    user: {
      query: GET_USER,
    },
    tags: {
      query: GET_TAGS,
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$apollo.mutate({
        mutation: UPDATE_HEADER_CONFIG,
        variables: {
          headerConfig: {
            title: '棋譜',
            middle: 'title',
          },
        },
      });
    });
  },
  methods: {
    goToTournament(id) {
      this.$router.push({name: 'kifu-tournament', params: {id}});
    },
    goToKifus(query) {
      this.$router.push({name: 'kifus', query});
    },
    goToSearch() {
      this.$router.push({name: 'kifu-search'});
    },
    goToMyKifu(query) {
      this.$router.push({name: 'my-kifus', query});
    },
  },
};
</script>

<style lang="scss" scoped>
#kifu-category {
  .kifu-type {
    height: 222px;
  }
}
</style>
