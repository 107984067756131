import Vue from 'vue';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import InfiniteLoading from 'vue-infinite-loading';
import VueGtag from 'vue-gtag';
import LoadScript from 'vue-plugin-load-script';
import * as capacitorApp from '@capacitor/app';
import App from './App.vue';
import router from './router';
import './assets/scss/global.scss';
import './assets/icomoon/style.css';
import './lib/importBaseComponents'; // 載入所有位於 src/components/Base 內的vue component
import soundService from './lib/base/soundService';
import Message from './lib/message';
import {auth} from './lib/directives';
import {createProvider} from '@/vueApollo';
import UPDATE_LOADING from '@/graphql/mutations/UpdateLoading.gql';
import Print from 'vue-print-nb';

Vue.config.productionTip = false;

Vue.prototype.$playSound = (...args) => {
  soundService.playSound(...args);
};

Vue.prototype.$bus = new Vue();
Vue.use(Print);
Vue.use(Toast);
Vue.use(InfiniteLoading);
Vue.use(LoadScript);
Vue.prototype.$Message = Message;
Vue.directive('auth', auth);

if (process.env.NODE_ENV === 'production') {
  Vue.use(
    VueGtag,
    {
      config: {id: process.env.VUE_APP_GTAG_ID},
    },
    router,
  );
}

capacitorApp.App.addListener('appUrlOpen', (data) => {
  const url = new URL(data.url);
  if (url.hash) router.push({path: url.hash.replace('#', '')});
});

window.addEventListener('pageshow', (event) => {
  const historyTraversal =
    event.persisted ||
    (typeof window.performance !== 'undefined' &&
      window.performance.navigation.type === 2);
  if (historyTraversal) {
    window.location.reload();
  }
});

const vueInstance = new Vue({
  router,
  apolloProvider: createProvider(),
  render: (h) => h(App),
}).$mount('#app');

vueInstance.$router.beforeEach((to, from, next) => {
  vueInstance.$apollo.mutate({
    mutation: UPDATE_LOADING,
    variables: {
      isLoading: false,
    },
  });
  next();
});
