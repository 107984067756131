<template>
  <div class="banner">
    <swiper ref="mySwiper" class="h-full rounded-10" :options="swiperOptions">
      <swiper-slide v-for="(banner, index) in banners" :key="index">
        <img
          :src="banner.imgUrl"
          class="w-full h-full object-cover"
          @click="onclickBanner(banner)"
        />
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import GET_BANNERS from '@/graphql/queries/GetBanners.gql';
import config from '../../config.json';

export default {
  name: 'Banner',
  components: {
    Swiper,
    SwiperSlide,
  },

  apollo: {
    banners: {
      query: GET_BANNERS,
      result({data, loading}) {
        if (!loading) {
          this.banners = data.banners;
        }
      },
    },
  },
  data() {
    return {
      banners: [],
      swiperOptions: {
        loop: true,
        autoplay: {
          delay: 1000 * 2.5,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  mounted() {
    this.swiper.slideTo(1, 1000, false);
  },
  methods: {
    onclickBanner(banner) {
      if (banner.linkUrl) {
        const link = new URL(banner.linkUrl);
        if (link.hostname === config.hostname) {
          this.$router.push(link.hash.substring(2));
        } else {
          window.open(banner.linkUrl);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  @apply rounded-10;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
  height: calc(100vw * 0.45625);
  .swiper-pagination ::v-deep {
    .swiper-pagination-bullet {
      border-radius: 0;
      width: 30px;
      height: 3px;
    }
    .swiper-pagination-bullet-active {
      background: #34b2be;
    }
  }
}
</style>
