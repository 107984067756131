const baseURL = process.env.VUE_APP_HTTP;

async function postData(apiName, data) {
  const response = await fetch(`${baseURL}/${apiName}`, {
    body: JSON.stringify(data), // must match 'Content-Type' header
    credentials: 'include', // include, same-origin, *omit
    headers: {
      Accept: 'application/json',
      'content-type': 'application/json',
    },
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
  });

  if (response.status >= 400 && response.status < 600) {
    if (response.status === 401) {
      throw new Error('帳號或密碼錯誤');
    } else {
      throw new Error('發生錯誤');
    }
  }
}

async function login(data) {
  await postData('login', data);
}

async function logout() {
  await postData('logout');
}

export {login, logout};
