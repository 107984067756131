<template>
  <div
    class="base-text-area text-14"
    :class="[size, error ? 'error' : '', full ? 'full' : '']"
    :disabled="disabled"
    v-on:keyup.enter="$emit('submit')"
  >
    <textarea
      class="rounded-6 pt-sm pb-sm"
      :value="value"
      :placeholder="placeholder"
      :disabled="disabled"
      :class="{'pointer-events-none': onlyDisplay}"
      @input="update"
    />
  </div>
</template>

<script>
export default {
  name: 'BaseInput',
  props: {
    type: {
      type: String,
      default: 'text',
      validator(value) {
        return ['text', 'password', 'number'].includes(value);
      },
    },
    size: {
      type: String,
      default: 'medium',
      validator(value) {
        return ['large', 'medium', 'small'].includes(value);
      },
    },
    full: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    onlyDisplay: {
      // 純顯示，不能修改內容（比起disabled，不帶有原生style）
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    update(event) {
      this.$emit('input', event.target.value);
    },
  },
};
</script>

<style scoped lang="scss">
.base-text-area {
  &.error {
    input {
      @apply border border-solid border-i-red;
    }
  }
  &.medium {
    width: 200px;
    height: 80px;
  }
  &.full {
    width: 100%;
    textarea {
      width: 100%;
    }
  }

  textarea {
    @apply h-full;
    background-color: #ebf1fc;
    padding-left: 15px;
    resize: none;
    &:disabled {
      @apply cursor-not-allowed;
    }
  }
}
</style>
