/* eslint-disable require-jsdoc */
import UAParser from 'ua-parser-js';
/**
 * Check is electron
 * @returns {Boolean} is electron
 */
const checkIsElectron = () => {
  // Renderer process
  if (
    typeof window !== 'undefined' &&
    typeof window.process === 'object' &&
    window.process.type === 'renderer'
  ) {
    return true;
  }

  // Main process
  if (
    typeof process !== 'undefined' &&
    typeof process.versions === 'object' &&
    !!process.versions.electron
  ) {
    return true;
  }

  // Detect the user agent when the `nodeIntegration` option is set to true
  if (
    typeof navigator === 'object' &&
    typeof navigator.userAgent === 'string' &&
    navigator.userAgent.indexOf('Electron') >= 0
  ) {
    return true;
  }

  return false;
};

const checkIsWeChatMp = () => {
  const ua = window.navigator.userAgent.toLowerCase();
  const userAgentCheck = /micromessenger/i.test(ua) && /miniprogram/i.test(ua);
  const wxjsCheck = window.__wxjs_environment === 'miniprogram';
  return userAgentCheck || wxjsCheck;
};

const checkIsPwa = (viewMode) => {
  if (navigator.standalone) {
    return true;
  } else if (
    viewMode !== 'web' &&
    window.matchMedia('(display-mode: fullscreen)').matches
  ) {
    return true;
  } else {
    return false;
  }
};

/**
 * 取得裝置類別與翻轉資訊
 * @returns {Object} 裝置類別與翻轉資訊
 */
function getDeviceInfo() {
  let parser = new UAParser();
  let userAgent = parser.getResult();

  // let min, max;
  let height, width;
  let viewMode;

  // const useragent = new UA(navigator.userAgent);
  // if (
  //   navigator.userAgent.indexOf('iP') > -1 ||
  //   navigator.userAgent.indexOf('An') > -1
  // ) {
  //   useragent.device.type = 'mobile';
  // }
  // if (
  //   navigator.userAgent.indexOf('ios-goer-app') > -1 ||
  //   navigator.userAgent.indexOf('andoird-goer-app') > -1
  // ) {
  //   useragent.device.type = 'mobile';
  // }
  // const isMobile = useragent.device.type == 'mobile';

  width = window.innerWidth;
  height = window.innerHeight;

  const screenRatio = width / height;

  const webUaList = [
    'Windows',
    'CrOS x86',
    'Macintosh',
    'Linux x86',
    'Linux i686',
    'Linux amd64',
    'Linux x86_64',
  ];
  for (const str of webUaList) {
    if (navigator.userAgent.includes(str)) {
      viewMode = 'web';
      break;
    }
  }

  if (viewMode !== 'web') {
    if (width >= 860) {
      viewMode = 'pad';
    } else {
      viewMode = 'phone';
    }

    if (height <= 480) {
      viewMode = 'phone';
    }
  }

  let device = 'web';
  let isFlutterApp = false;

  if (userAgent.os.name === 'Android') {
    device = 'android';
  } else if (userAgent.os.name === 'iOS') {
    device = 'ios';
  }

  if (navigator.userAgent.indexOf('ios-goer-app') > -1) {
    isFlutterApp = true;
    device = 'ios';
  }

  if (
    navigator.userAgent.indexOf('andoird-goer-app') > -1 ||
    navigator.userAgent.indexOf('android-goer-app') > -1
  ) {
    isFlutterApp = true;
    device = 'android';
  }

  if (isFlutterApp && !window.flutterApp)
    window.flutterApp = {
      postMessage: () => {
        return;
      },
    };

  // detection for iPad since iPadOS user-agent is same as macintosh
  const iPad =
    userAgent.ua.match(/(iPad)/) /* iOS pre 13 */ ||
    (navigator.platform === 'MacIntel' &&
      navigator.maxTouchPoints > 1); /* iPad OS 13 */

  if (iPad) {
    device = 'ios';
    viewMode = 'pad';
  }

  const isCapacitorApp = userAgent.ua.match(/(capacitor)/);
  const isPwa = checkIsPwa(viewMode);
  const isElectron = checkIsElectron();
  const isWeChatMp = checkIsWeChatMp();

  const isLite = viewMode === 'phone' && !isPwa && !isElectron && !isFlutterApp;

  return {
    viewMode,
    device,
    isElectron,
    isFlutterApp,
    isCapacitorApp,
    isPwa,
    isWeChatMp,
    screenRatio,
    isLite,
  };
}

export default getDeviceInfo;
