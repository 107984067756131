<template>
  <div class="kifu-form-public w-full h-full bg-white z-modal text-12">
    <div class="flex flex-col">
      <div class="item m-md">
        賽事名稱
        <BaseInput
          full
          onlyDisplay
          placeholder="請選擇"
          right="select"
          v-model="kifu.series.tournament.name"
          @click="isTournamentSearchShow = true"
        ></BaseInput>
      </div>
      <div class="item m-md" v-if="kifu.series.tournament.serieses">
        對局名稱
        <BaseInput
          full
          onlyDisplay
          placeholder="請選擇"
          right="select"
          :value="kifu.series.name"
          @click="isSeriesSearchShow = true"
        ></BaseInput>
      </div>
      <div class="flex">
        <div class="item w-1/2 m-md mr-sm">
          黑方姓名
          <BaseInput
            full
            onlyDisplay
            placeholder="請選擇"
            right="select"
            :value="kifu.blackPlayer.name"
            @click="isBlackPlayerSearchShow = true"
          ></BaseInput>
        </div>
        <div class="item w-1/2 m-md ml-sm">
          白方姓名
          <BaseInput
            full
            onlyDisplay
            v-model="kifu.whitePlayer.name"
            placeholder="請選擇"
            right="select"
            @click="isWhitePlayerSearchShow = true"
          ></BaseInput>
        </div>
      </div>
      <div class="item m-md">
        貼目
        <BaseInput full v-model.number="kifu.komi"></BaseInput>
      </div>
      <div v-if="!isStream" class="item m-md">
        勝負
        <BaseInput full v-model="kifu.winningWay"></BaseInput>
      </div>
      <div class="item m-md">
        日期
        <BaseInput
          type="date"
          :onlyDisplay="isStream"
          full
          v-model="gameStartedAtText"
        ></BaseInput>
      </div>
    </div>
    <div v-if="!isStream" class="text-center pt-lg pb-lg">
      <div class="text-16 text-f-black">點擊右上方提交</div>
      <div class="text-12 text-i-dutch-blue">審批通過後將發佈此棋譜</div>
    </div>

    <SearchTournament
      :show="isTournamentSearchShow"
      @close="isTournamentSearchShow = false"
      @select="selectTournament"
    ></SearchTournament>
    <SearchSeries
      :show="isSeriesSearchShow"
      :serieses="kifu.series.tournament.serieses"
      @close="isSeriesSearchShow = false"
      @select="selectSeries"
    ></SearchSeries>
    <SearchPlayer
      :show="isBlackPlayerSearchShow"
      title="請選擇黑方"
      @close="isBlackPlayerSearchShow = false"
      @select="
        kifu.blackPlayer = $event;
        isBlackPlayerSearchShow = false;
      "
    ></SearchPlayer>
    <SearchPlayer
      :show="isWhitePlayerSearchShow"
      title="請選擇白方"
      @close="isWhitePlayerSearchShow = false"
      @select="
        kifu.whitePlayer = $event;
        isWhitePlayerSearchShow = false;
      "
    ></SearchPlayer>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import TIME from '@/constant/time';
import CREATE_PUBLIC_KIFU from '@/graphql/mutations/CreatePublicKifu.gql';
import UPDATE_HEADER_CONFIG from '@/graphql/mutations/UpdateHeaderConfig.gql';
import SearchTournament from '@/components/Search/SearchTournament';
import SearchSeries from '@/components/Search/SearchSeries';
import SearchPlayer from '@/components/Search/SearchPlayer';

export default {
  name: 'KifuFormPublic',
  components: {
    SearchTournament,
    SearchSeries,
    SearchPlayer,
  },
  props: {
    kifu: {
      type: Object,
      default: () => {
        return {
          series: {
            name: '',
            tournament: {
              name: '',
            },
          },
          blackPlayer: {
            name: '',
          },
          whitePlayer: {
            name: '',
          },
          winningWay: '',
          komi: '6.5目',
        };
      },
    },
    sgf: {
      type: String,
      default: '(;CA[big5]SZ[19])',
    },
    isStream: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isTournamentSearchShow: false,
      isSeriesSearchShow: false,
      isBlackPlayerSearchShow: false,
      isWhitePlayerSearchShow: false,
      gameStartedAtText: dayjs(this.kifu.gameStartedAt).format('YYYY-MM-DD'),
    };
  },
  watch: {
    kifu: {
      deep: true,
      handler(value) {
        const isAbled =
          value.series.id &&
          value.blackPlayer.id &&
          value.whitePlayer.id &&
          (value.winningWay || this.isStream) &&
          this.gameStartedAtText;
        this.$apollo.mutate({
          mutation: UPDATE_HEADER_CONFIG,
          variables: {
            headerConfig: {
              nextDisabled: !isAbled,
            },
            update: true,
          },
        });
      },
    },
  },
  created() {
    this.$bus.$on('header:next', async () => {
      const result = await this.$apollo.mutate({
        mutation: CREATE_PUBLIC_KIFU,
        variables: {
          input: {
            seriesId: this.kifu.series.id,
            blackPlayerId: this.kifu.blackPlayer.id,
            whitePlayerId: this.kifu.whitePlayer.id,
            komi: String(this.kifu.komi),
            gameStartedAt: new Date(this.gameStartedAtText),
            sgf: this.sgf,
            winningWay: this.kifu.winningWay,
            isPublished: this.isStream, // 直播棋譜無須審核，直接公開通過
            isPopular: false,
            isStreaming: this.isStream,
            source: this.isStream ? 'STREAM' : 'PRO',
          },
        },
      });
      this.$Message.success('已成功提交', 1000 * 1.5);
      setTimeout(() => {
        if (this.isStream) {
          this.$router.push({
            name: 'kifu',
            params: {id: result?.data?.createPublicKifu?.id},
          });
        } else {
          this.$router.push({name: 'my-kifus'});
        }
      }, TIME.SUCCESS_ROUTER_CHANGE);
    });

    this.$bus.$on('header:close', async () => {
      this.$emit('close');
    });
  },
  beforeDestroy() {
    this.$bus.$off('header:next');
    this.$bus.$off('header:close');
  },
  methods: {
    selectTournament(tournament) {
      this.kifu.series = {
        name: '',
        tournament,
      };
      this.isTournamentSearchShow = false;
    },
    selectSeries(series) {
      Object.assign(this.kifu.series, series);
      this.isSeriesSearchShow = false;
    },
  },
};
</script>

<style scoped lang="scss">
.kifu-form-public {
  .item {
    margin-top: 24px;
    height: 50px;
    &.big {
      height: 80px;
    }
  }
}
</style>
