<template>
  <div class="login bg-white w-full h-full flex flex-col">
    <div class="w-full relative flex items-center justify-center">
      <img class="w-full" :src="LoginBackgroundImage" />
      <div class="absolute flex flex-col items-center text-white">
        <span>Welcome to</span>
        <img :src="Logo" alt="logo" class="mt-sm" />
      </div>
      <div
        class="absolute right-sm top-sm text-white text-16 cursor-pointer"
        @click="goBack"
      >
        Skip
      </div>
    </div>

    <div class="flex-1 flex flex-col flex-grow items-center justify-center">
      <div class="flex flex-col">
        <div class="flex flex-col items-center justify-center">
          <div
            v-if="isShowAppleLoginPlatformList.includes(deviceInfo.platform)"
            id="appleid-signin"
            class="mb-sm"
            data-width="240"
            data-height="40"
            data-logo-size="large"
            data-border-radius="10"
            data-color="black"
            data-border="true"
            data-type="sign in"
          ></div>
          <BaseButton
            id="google-login"
            class="mb-sm backgroundColor-background"
            type="plain"
            @click="googleSignIn"
          >
            <img :src="GoogleImage" class="mr-sm" />使用 Google 登入
          </BaseButton>
          <!-- <BaseButton v-if="isShowAppleLoginPlatformList.includes(deviceInfo.platform)" class="mb-sm" type="plain" @click="appleSignIn">
            <img :src="AppleImage" class="mr-sm" />Apple登入
          </BaseButton> -->
          <!-- <BaseButton class="mb-sm" :style="buttonFacebookStyle">
            Facebook
          </BaseButton>
          <BaseButton class="mb-sm" :style="buttonLineStyle">Line</BaseButton> -->
          <!-- <BaseButton type="plain" @click="goBack">訪客</BaseButton> -->
        </div>

        <BaseLine class="mt-md mb-md" />

        <div class="pt-4 flex flex-col items-center justify-center">
          <LoginModal></LoginModal>
          <div class="text-12 mt-sm text-f-black" @click="openProtocol">
            註冊登入後代表您同意
            <span class="font-bold underline">服務條款</span>
            和
            <span class="font-bold underline">隱私政策</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '@/assets/img/logo.svg';
import LoginBackgroundImage from '@/assets/img/bg-login.svg';
import GoogleImage from '@/assets/img/google.png';
import AppleImage from '@/assets/img/apple.svg';
import LoginModal from '@/components/Login/LoginModal';
import UPDATE_LOGIN_MODAL_SHOW from '@/graphql/mutations/UpdateLoginModalShow.gql';
import {login} from '@/vueApollo';
import '@codetrix-studio/capacitor-google-auth';
import {Device} from '@capacitor/device';
import {GoogleAuth} from '@codetrix-studio/capacitor-google-auth';

export default {
  name: 'Login',
  components: {
    LoginModal,
  },
  data() {
    return {
      Logo,
      LoginBackgroundImage,
      GoogleImage,
      AppleImage,
      buttonLineStyle: {
        backgroundColor: '#55bf6d',
      },
      deviceInfo: {},
      showLoginModal: false, // 帳號密碼登入(官方人員)modal
      username: '', // 使用者帳號
      password: '', // 使用者密碼
      isShowAppleLoginPlatformList: ['web', 'ios'],
    };
  },
  async created() {
    this.deviceInfo = await Device.getInfo();

    /* Apple 第三方登入 */
    if (this.isShowAppleLoginPlatformList.includes(this.deviceInfo.platform)) {
      this.appleLoginInit();
    }
    await GoogleAuth.initialize();
  },
  methods: {
    async goBack() {
      if (this.$route.name == 'login') {
        this.$router.push({name: 'more'});
        return;
      }
      this.$apollo.mutate({
        mutation: UPDATE_LOGIN_MODAL_SHOW,
        variables: {
          isLoginModalShow: false,
        },
      });
    },
    async googleSignIn() {
      try {
        const googleUser = await GoogleAuth.signIn();
        await login(
          {
            idToken: googleUser.authentication.idToken,
            platform: this.deviceInfo.platform,
          },
          'GOOGLE',
        );
        this.$Message.success('登入成功');
        this.goBack();
      } catch (error) {
        console.log(error);
        const message = typeof error === 'string' ? error : '登入失敗';
        this.$Message.error(message);
      }
    },
    async appleLoginInit() {
      await this.$loadScript(
        'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/zh_TW/appleid.auth.js',
      );
      const state = {
        origin: location.origin.replace('capacitor://', 'https://'),
        routeName: this.$route.name,
      };
      AppleID.auth.init({
        clientId: process.env.VUE_APP_APPLE_CLIENT_ID,
        scope: 'name email',
        redirectURI: `${process.env.VUE_APP_HTTP}/appleAuth`,
        state: JSON.stringify(state),
        responseMode: 'form_post',
      });
    },
    async appleSignIn() {
      try {
        await AppleID.auth.signIn();
      } catch (error) {
        this.$Message.error('登入失敗');
      }
    },
    openProtocol() {
      window.open('/protocol.html');
    },
  },
};
</script>

<style lang="scss">
#google-login {
  font-size: 17px;
  color: black;
  border-radius: 4px;
  border: solid 1px black;
}
</style>
