const errors = {
  INCORRECT_OLD_PASSWORD: '密碼錯誤，請重新輸入',
};

const convertError = (error) => {
  if (
    error instanceof Error &&
    error.graphQLErrors &&
    error.graphQLErrors.length > 0
  ) {
    let message = null;
    const errorMessages = error.graphQLErrors.map((graphQLError) => {
      const errorCode = graphQLError.extensions.code;
      let errorMessage = '';
      if (errors[errorCode]) {
        errorMessage = errors[errorCode];
      } else {
        errorMessage = errorCode;
      }
      return errorMessage;
    });
    message = errorMessages.join('\n');
    return message;
  } else if (errors[error]) {
    return errors[error];
  } else {
    console.error('錯誤不存在');
    // throw new Error('錯誤不存在');
    return error;
  }
};

export {errors, convertError};
