<template>
  <div id="setting" class="p-md">
    <div class="text-12 text-black-50">聲音</div>
    <RowItem>
      落子聲音
      <template v-slot:right>
        <BaseCheckbox v-model="tempSetting.isLoaziSound"></BaseCheckbox>
      </template>
    </RowItem>
    <RowItem class="mt-sm">
      讀秒聲音
      <template v-slot:right>
        <BaseCheckbox v-model="tempSetting.isByoYomiSound"></BaseCheckbox>
      </template>
    </RowItem>
    <div class="text-12 text-black-50 mt-lg">棋盤顏色</div>
    <div class="mt-sm flex">
      <RowItem class="flex-1">
        <div class="color-block bg-board1"></div>
        <template v-slot:right>
          <BaseCheckbox
            :value="tempSetting.boardColor == '1'"
            @input="tempSetting.boardColor = '1'"
          ></BaseCheckbox>
        </template>
      </RowItem>
      <RowItem class="flex-1 ml-sm">
        <div class="color-block bg-board2"></div>
        <template v-slot:right>
          <BaseCheckbox
            :value="tempSetting.boardColor == '2'"
            @input="tempSetting.boardColor = '2'"
          ></BaseCheckbox>
        </template>
      </RowItem>
    </div>
    <div v-if="user" class="text-center">
      <BaseLine class="mt-lg mb-lg"></BaseLine>
      <ChangePassword></ChangePassword>
      <DeleteAccount></DeleteAccount>
    </div>
  </div>
</template>

<script>
import ChangePassword from '@/components/Setting/ChangePassword';
import DeleteAccount from '@/components/Setting/DeleteAccount';

import UPDATE_HEADER_CONFIG from '@/graphql/mutations/UpdateHeaderConfig.gql';
import UPDATE_SETTING from '@/graphql/mutations/UpdateSetting.gql';
import GET_SETTING from '@/graphql/queries/GetSetting.gql';
import GET_USER from '@/graphql/queries/GetUser.gql';

export default {
  name: 'Setting',
  components: {
    ChangePassword,
    DeleteAccount,
  },
  apollo: {
    tempSetting: {
      query: GET_SETTING,
      update: (data) => data.setting,
    },
    user: {
      query: GET_USER,
    },
  },
  data() {
    return {
      tempSetting: {
        isLoaziSound: true,
        isByoYomiSound: true,
        boardColor: '1',
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$apollo.mutate({
        mutation: UPDATE_HEADER_CONFIG,
        variables: {
          headerConfig: {
            left: 'back',
            title: '設定',
            middle: 'title',
          },
        },
      });
    });
  },
  watch: {
    tempSetting: {
      deep: true,
      handler() {
        this.update();
      },
    },
  },
  methods: {
    update() {
      this.$apollo.mutate({
        mutation: UPDATE_SETTING,
        variables: {
          setting: this.tempSetting,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#setting {
  .color-block {
    @apply rounded-6;
    height: 33px;
    width: 33px;
  }
}
</style>
