<template>
  <hr class="base-line" />
</template>

<script>
export default {
  name: 'BaseLine',
};
</script>

<style scoped lang="scss">
.base-line {
  border-color: #e2e2e2;
}
</style>
