import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home';
import KifuCategory from '@/views/KifuCategory';
import KifuTournament from '@/views/KifuTournament';
import KifuSearch from '@/views/KifuSearch';
import MyKifus from '@/views/MyKifus';
import Kifus from '@/views/Kifus';
import Kifu from '@/views/Kifu';
import PrivateKifu from '@/views/PrivateKifu';
import RecordKifu from '@/views/RecordKifu';
import Newses from '@/views/Newses';
import News from '@/views/News';
import More from '@/views/More';
import Setting from '@/views/Setting';
import Login from '@/components/Login';
import WebHome from '@/views/WebHome';
import WebKifu from '@/views/WebKifu';
import AppleAuth from '@/views/AppleAuth';
import getDeviceInfo from '@/lib/getDeviceInfo';
const {viewMode, isCapacitorApp} = getDeviceInfo();

Vue.use(VueRouter);

let isWebRoutes = ['web', 'pad'].includes(viewMode) && !isCapacitorApp;
let onlyHomePage = false;
if (!isWebRoutes && window.location.hash.indexOf('#/kifu/') === 0) {
  isWebRoutes = true;
  onlyHomePage = true;
}

const webRoutes = [
  {
    path: '*',
    redirect: '/',
  },
  {
    path: '/',
    name: 'web-home',
    component: WebHome,
  },
  {
    path: '/kifu/:id',
    name: 'kifu',
    component: WebKifu,
  },
];
if (onlyHomePage) webRoutes.pop();

const phoneRoutes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/kifu-category',
    name: 'kifu-category',
    component: KifuCategory,
  },
  {
    path: '/kifu-tournament/:id',
    name: 'kifu-tournament',
    component: KifuTournament,
  },
  {
    path: '/kifus',
    name: 'kifus',
    component: Kifus,
  },
  {
    path: '/kifu/:id',
    name: 'kifu',
    component: Kifu,
  },
  {
    path: '/private-kifu/:id',
    name: 'private-kifu',
    component: PrivateKifu,
  },
  {
    path: '/record-kifu/:type',
    name: 'record-kifu',
    component: RecordKifu,
  },
  {
    path: '/newses',
    name: 'newses',
    component: Newses,
  },
  {
    path: '/news/:id',
    name: 'news',
    component: News,
  },
  {
    path: '/more',
    name: 'more',
    component: More,
  },
  {
    path: '/setting',
    name: 'setting',
    component: Setting,
  },
  {
    path: '/live-kifus',
    name: 'live-kifus',
    component: () => import('@/views/LiveKifus'),
  },
  {
    path: '/kifu-search',
    name: 'kifu-search',
    component: KifuSearch,
  },
  {
    path: '/my-kifus',
    name: 'my-kifus',
    component: MyKifus,
  },
  {
    path: '/apple-auth',
    name: 'apple-auth',
    component: AppleAuth,
  },
];

const routes = isWebRoutes ? webRoutes : phoneRoutes;

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes,
});

export default router;
