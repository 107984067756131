<template>
  <div id="kifu-search" class="p-md">
    <!-- <div>
      <BaseInput v-model="searchText" full placeholder="請輸入關鍵字" />
    </div> -->
    <div>
      <BaseInput v-model="tournamentName" full placeholder="請輸入賽事名稱" />
    </div>
    <div class="mt-md">
      <BaseInput v-model="playerName" full placeholder="請輸入棋手名稱" />
    </div>
    <div class="mt-md flex">
      <BaseInput
        v-model="startDate"
        type="date"
        size="auto"
        placeholder="請選擇日期"
        class="flex-1"
        style="width: 0"
      />
      <div class="flex items-center ml-xs mr-xs">~</div>
      <BaseInput
        v-model="endDate"
        type="date"
        size="auto"
        placeholder="請選擇日期"
        class="flex-1"
        style="width: 0"
      />
    </div>
    <div class="mt-md">
      <BaseButton @click="submit" full size="full" style="height: 40px"
        >搜尋</BaseButton
      >
    </div>
  </div>
</template>

<script>
import UPDATE_HEADER_CONFIG from '@/graphql/mutations/UpdateHeaderConfig.gql';

export default {
  name: 'KifuSearch',
  data() {
    return {
      searchText: '',
      tournamentName: '',
      playerName: '',
      startDate: '',
      endDate: '',
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$apollo.mutate({
        mutation: UPDATE_HEADER_CONFIG,
        variables: {
          headerConfig: {
            left: 'back',
            title: '棋譜查詢',
            middle: 'title',
          },
        },
      });
    });
  },
  methods: {
    submit() {
      const query = {};
      // if(this.searchText) {
      //   query.search = this.searchText;
      // }
      if (this.tournamentName) {
        query.tournamentName = this.tournamentName;
      }
      if (this.playerName) {
        query.playerName = this.playerName;
      }
      if (this.startDate) {
        query.gameStartedAfter = this.startDate;
      }
      if (this.endDate) {
        query.gameStartedBefore = this.endDate;
      }
      this.$router.push({name: 'kifus', query});
    },
  },
};
</script>

<style lang="scss" scoped>
#kifu-search {
}
</style>
