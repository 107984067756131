<template>
  <button
    class="
      base-button
      inline-flex
      cursor-pointer
      justify-center
      items-center
      font-bold
    "
    :class="[type, size]"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    type: {
      type: String,
      default: 'primary',
      validator(value) {
        return [
          'custom',
          'plain',
          'primary',
          'success',
          'warning',
          'error',
        ].includes(value);
      },
    },
    size: {
      type: String,
      default: 'medium',
      validator(value) {
        return ['full', 'auto', 'large', 'medium', 'small'].includes(value);
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.base-button {
  @apply text-f-black;

  &.plain {
    @apply bg-background  border border-solid border-gray;
  }
  &.primary {
    @apply bg-primary text-white;
  }
  &.success {
    @apply bg-i-green text-white;
  }
  &.warning {
    @apply bg-i-orange text-white;
  }
  &.error {
    @apply bg-error text-white;
  }

  &.full {
    @apply rounded-6 w-full;
    padding: 0 10px;
    height: 36px;
  }
  &.auto {
    @apply rounded-6;
    padding: 0 10px;
    height: 36px;
    width: auto;
  }
  &.small {
    @apply rounded-6;
    padding: 0 10px;
    height: 36px;
    width: 144px;
  }
  &.medium {
    @apply rounded-6;
    min-width: 240px;
    height: 40px;
  }
}
</style>
