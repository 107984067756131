import gql from 'graphql-tag';

export default gql`
  extend type HeaderConfig {
    title: String
    left: String
    leftCustomized: Boolean
    middle: String
    right: String
    rightIcons: Array
    nextText: String
    nextDisabled: Boolean
  }
`;
