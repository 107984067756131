const defaultHeaderConfig = {
  title: '',
  left: '',
  leftCustomized: false,
  middle: '',
  right: '',
  rightIcons: [],
  nextText: '',
  nextDisabled: false,
  __typename: 'headerConfig',
};
const defaultSetting = {
  isLoaziSound: true,
  isByoYomiSound: true,
  boardColor: '1',
  __typename: 'setting',
};
const defaultSgf = '';

export {defaultHeaderConfig, defaultSgf, defaultSetting};
