<template>
  <div
    id="kifu"
    class="relative flex h-full py-md overflow-auto justify-center"
  >
    <div
      class="kifu-board mr-sm bg-white w-full h-full border border-solid border-gray p-md ml-md rounded-6"
    >
      <Board
        class="board-container"
        id="player"
        :testPlay="isTestPlay"
        :lock="isBoardLock"
      ></Board>

      <div
        class="control-container flex items-center justify-center text-white"
      >
        <button
          v-for="btn in rightControlBtns"
          :key="btn.key"
          class="flex items-center justify-center mr-md bg-primary rounded-6"
          :class="{'btn-text font-bold': btn.name}"
          :style="{width: btn.name ? '91px' : ''}"
          @click="onClickBtn(btn.value)"
        >
          <span v-if="btn.name"> {{ btn.name }} </span>
          <i v-else :class="btn.icon" />
        </button>

        <div
          class="step flex items-center text-f-black font-bold"
          style="margin: 0 20px;"
        >
          {{ showStep }} / {{ showCompleteStep }}
        </div>

        <button
          v-for="btn in leftControlBtns"
          :key="btn.key"
          class="flex items-center justify-center ml-md bg-primary rounded-6"
          :class="{'bg-orange btn-text font-bold': btn.name}"
          :style="{width: btn.name ? '91px' : ''}"
          @click="onClickBtn(btn.value)"
        >
          <span v-if="btn.name"> {{ btn.name }} </span>
          <i v-else :class="btn.icon" />
        </button>
      </div>
      <div
        class="pad-mode control-container flex items-center justify-between text-white mt-md"
      >
        <button
          class="flex items-center justify-center bg-primary rounded-6 font-bold"
          style="width: 91px;"
          @click="toggleStep"
        >
          <span> 顯示手數 </span>
        </button>

        <button
          class="flex items-center justify-center bg-orange rounded-6 font-bold"
          style="width: 91px;"
          @click="toggleTestPlay"
        >
          <span> {{ isTestPlay ? '離開試下' : '進入試下' }} </span>
        </button>
      </div>
    </div>
    <div
      class="kifu-detail bg-white h-full border border-solid border-gray flex flex-col mr-md rounded-6"
    >
      <div
        class="detail-wrapper overflow-auto h-full p-md flex flex-col justify-between"
      >
        <div>
          <div
            v-show="!isOnlyBoard && ['view', 'single'].includes(action)"
            class="bg-background p-md"
            style="border-radius: 3px;"
          >
            <div class="flex items-center mb-md">
              <BaseAvatar
                :url="kifu.blackPlayer.avatarUrl"
                :width="40"
                :dotWidth="20"
                color="black"
              />
              <div class="ml-sm text-14 ">
                <div class="player-name">
                  {{ kifu.blackPlayer.name || '黑方' }}
                </div>
                提子：{{ capCount.black || 0 }}
              </div>
            </div>
            <div class="flex items-center">
              <BaseAvatar
                :url="kifu.whitePlayer.avatarUrl"
                :width="40"
                :dotWidth="20"
                color="white"
              />
              <div class="ml-sm text-14">
                <div class="player-name">
                  {{ kifu.whitePlayer.name || '白方' }}
                </div>
                提子：{{ capCount.white || 0 }}
              </div>
            </div>
          </div>

          <div class="mt-md" v-for="detail in kifuDetails" :key="detail.value">
            <div class="text-12 mb-xs text-f-black opacity-50">
              {{ detail.name }}
            </div>
            <div class="content text-14 mb-sm">{{ detail.content }}</div>
            <div class="divider-line bg-gray" style="height: 1px;"></div>
          </div>
        </div>

        <div class="qrcode" style="margin-top: 40px;">
          <img :src="qrCodeImage" width="100" alt="" style="margin: 0 auto;" />
          <p class="text-12 mt-sm text-center" style="color: black;">
            立即下載 HiGO 圍棋
          </p>
        </div>
      </div>
      <div class="action-container py-sm px-md">
        <BaseButton
          class="download-btn"
          type="plain"
          size="small"
          v-print="print"
        >
          列印
        </BaseButton>
        <BaseButton
          class="download-btn"
          type="plain"
          size="small"
          @click="downloadSgf"
        >
          下載SGF
        </BaseButton>
      </div>
    </div>
    <div>
      <PrintSgf id="printArea" :qrCodeImage="qrCodeImage" :kifu="this.kifu" />
    </div>
  </div>
</template>

<script>
import WgoHelper from 'wgo-helper';
import boardMixin from '@/mixins/boardMixin';
import loadingMixin from '@/mixins/loadingMixin';
import kifuMixin from '@/mixins/kifuMixin';
import Board from '@/components/Common/Board';
import PrintSgf from '@/components/Common/PrintSgf.vue';
import GET_PUBLIC_KIFU from '@/graphql/queries/GetPublicKifu.gql';
import UPDATE_HEADER_CONFIG from '@/graphql/mutations/UpdateHeaderConfig.gql';
import UPDATE_LOADING from '@/graphql/mutations/UpdateLoading.gql';
import dayjs from 'dayjs';
import {toDataURL} from 'qrcode';

export default {
  name: 'Kifu',
  components: {
    Board,
    PrintSgf,
  },
  mixins: [boardMixin, loadingMixin, kifuMixin],
  apollo: {
    publicKifu: {
      query: GET_PUBLIC_KIFU,
      variables() {
        return {
          id: Number(this.$route.params.id),
        };
      },
      result({data, loading}) {
        if (!loading && (data == null || data.publicKifu.isStreaming)) {
          this.$router.push({name: 'web-home'});
          this.$apollo.mutate({
            mutation: UPDATE_LOADING,
            variables: {
              isLoading: false,
            },
          });
        } else if (!loading && data) {
          this.kifu = data.publicKifu;
          this.initialPlayer(this.kifu.sgf);
        }
      },
      skip() {
        return this.kifu?.id != null;
      },
    },
  },
  data() {
    return {
      kifuMode: 'live',
      kifu: {
        series: {
          name: '',
          tournament: {
            name: '',
          },
        },
        blackPlayer: {
          name: '',
        },
        whitePlayer: {
          name: '',
        },
      },
      qrCodeImage: '',
      boardImage: '',
      print: {
        id: 'printArea',
        popTitle: '',
      },
    };
  },
  computed: {
    kifuDetails() {
      return [
        {
          key: 'competition-name',
          name: '賽事名稱',
          content: this.kifu.series.tournament.name,
        },
        {
          key: 'game-name',
          name: '對局名稱',
          content: this.kifu.series.name,
        },
        {
          key: 'komi',
          name: '貼目',
          content: this.kifu.komi,
        },
        {
          key: 'winningWay',
          name: '勝負',
          content: this.kifu.winningWay,
        },
        {
          key: 'date',
          name: '日期',
          content: this.kifu.gameStartedAt
            ? dayjs(this.kifu.gameStartedAt).format('YYYY-MM-DD')
            : '',
        },
      ];
    },
    leftControlBtns() {
      return [
        {
          key: 'play',
          icon: 'icon-Play',
          value: 'next',
        },
        {
          key: 'fastForward1',
          icon: 'icon-FastForward1',
          value: 'next10',
        },
        {
          key: 'fastForward10',
          icon: 'icon-FastForward10',
          value: 'last',
        },
        {
          key: 'test-play',
          name: this.isTestPlay ? '離開試下' : '進入試下',
          value: 'test-play',
        },
      ];
    },
    rightControlBtns() {
      return [
        {
          key: 'step',
          name: '顯示手數',
          value: 'step',
        },
        {
          key: 'rewind10',
          icon: 'icon-Rewind10',
          value: 'first',
        },
        {
          key: 'rewind1',
          icon: 'icon-Rewind1',
          value: 'prev10',
        },
        {
          key: 'rewind',
          icon: 'icon-Rewind',
          value: 'prev',
        },
      ];
    },
    isBoardLock() {
      return !this.isTestPlay;
    },
  },
  created() {
    this.generateQrcode();
  },
  methods: {
    async generateQrcode() {
      // TODO 待後續正確導向網址
      this.qrCodeImage = await toDataURL(window.location.href, {
        errorCorrectionLevel: 'L',
      });
    },
    downloadSgf() {
      const competitionTitle = this.kifu.series.tournament.name;
      const gameTitle = this.kifu.series.name;
      const blackTitle = `(黑)${this.kifu.blackPlayer.name}`;
      const whiteTitle = `(白)${this.kifu.whitePlayer.name}`;
      const timeText = dayjs(this.kifu.gameStartedAt).format('YYYYMMDD');
      let fileName = `${competitionTitle}-${gameTitle}-${blackTitle}-${whiteTitle}-${timeText}.sgf`;

      const sgf = this.kifu.sgf;
      const element = document.createElement('a');
      element.setAttribute(
        'href',
        'data:text/plain;charset=utf-8,' + encodeURIComponent(sgf),
      );
      element.setAttribute('download', fileName);
      element.click();
    },
    shareKifu() {
      // 暫放這裡，可再判斷是否丟進 kifuMixin
    },
    initialPlayer(sgf = '(;CA[big5]SZ[19])') {
      const theme = {stoneHandler: 'GLOW'};
      this.wgoHelper = new WgoHelper('game', 'player', sgf, {
        theme,
      });

      this.wgoHelper.on('update', () => {
        this.capCount = this.wgoHelper.captureCount;
        this.turnColor = this.wgoHelper.turnColor;

        this.completeStep = this.wgoHelper.completeStep;
        if (this.isTestPlay) {
          this.testPlayCompleteStep = this.wgoHelper.completeStep;
          this.testPlayStep = this.wgoHelper.step;
        } else {
          this.step = this.wgoHelper.step;
        }
      });
      this.isCoordinatesShow = this.wgoHelper.toggleCoordinates(true);
      this.wgoHelper.last();
      this.capCount = this.wgoHelper.captureCount;
      this.turnColor = this.wgoHelper.turnColor;
      this.completeStep = this.wgoHelper.completeStep;
      this.step = this.wgoHelper.step;
    },
    initialHeaderConfig() {
      this.$bus.$off('header:next');
      this.$bus.$off('header:close');
      this.$apollo.mutate({
        mutation: UPDATE_HEADER_CONFIG,
        variables: {
          headerConfig: {
            middle: 'logo',
          },
        },
      });
    },
    onClickBtn(value) {
      if (value === 'test-play') {
        this.toggleTestPlay();
      } else if (value === 'step') {
        this.toggleStep();
      } else {
        this.onBoardControlerClick(value);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#kifu {
  margin: 0 auto;
  max-width: 1200px;
  min-width: calc(100vh - 208px + 222px + 32px + 32px + 8px);
  .board-container {
    height: calc(100vh - 208px);
    width: calc(100vh - 208px);
    margin: 0 auto 16px;
    &.is-test-play {
      box-shadow: 0 0 0 10px #f0a141;
    }
  }

  .kifu-detail {
    min-width: 222px;
    max-width: 222px;
  }
  .control-container {
    button {
      width: 36px;
      height: 36px;
    }
    i {
      font-size: 20px;
      &:before {
        @apply text-white;
      }
    }
    &.pad-mode {
      display: none;
    }
  }
  .action-container {
    @apply border-solid border-gray border-t;
    display: flex;
  }
  .download-btn {
    flex: 1;
    @apply bg-white;
    &:not(:first-child) {
      @apply ml-md;
    }
  }
}

@media screen and (max-width: 768px) {
  #kifu {
    max-width: 720px;
    min-width: 720px;
    .kifu-board {
      width: 458px;
    }
    .board-container {
      height: calc(458px - 32px);
      width: calc(458px - 32px);
    }
    .btn-text {
      display: none;
    }
    .control-container {
      justify-content: space-between;
      &.pad-mode {
        @apply flex;
      }
    }
  }
}
</style>
