<template>
  <div>
    <BaseButton type="primary" @click="showModal = true"> 官方人員 </BaseButton>
    <BaseModal v-model="showModal" title="官方人員">
      <BaseInput
        v-model="authId"
        class="mb-lg"
        type="text"
        icon="icon-Avatar"
        placeholder="請輸入帳號"
      ></BaseInput>
      <BaseInput
        v-model="password"
        class="mb-lg"
        type="password"
        icon="icon-Key"
        placeholder="請輸入密碼"
      ></BaseInput>

      <BaseButton class="w-full" type="primary" @click="login">
        登入
      </BaseButton>
    </BaseModal>
  </div>
</template>

<script>
import {login} from '@/vueApollo';
import UPDATE_LOGIN_MODAL_SHOW from '@/graphql/mutations/UpdateLoginModalShow.gql';

export default {
  data() {
    return {
      showModal: false,
      authId: '',
      password: '',
    };
  },
  methods: {
    async login() {
      try {
        await login({authId: this.authId, password: this.password});
        this.$Message.success('登入成功');
        this.goBack();
      } catch (error) {
        console.log(error);
        const message = typeof error === 'string' ? error :'登入失敗';
        this.$Message.error(message);
      }
    },
    async goBack() {
      if (this.$route.name == 'login') {
        this.$router.push({name: 'more'});
        return;
      }
      this.$apollo.mutate({
        mutation: UPDATE_LOGIN_MODAL_SHOW,
        variables: {
          isLoginModalShow: false,
        },
      });
    },
  },
};
</script>
