import {removeCanvasOnDestroy} from '@/lib/base/canvas.js';

const mixinObject = {
  methods: {
    onBoardControlerClick(eventName) {
      switch (eventName) {
        case 'first':
          this.wgoHelper.first();
          break;
        case 'prev10':
          this.wgoHelper.prev10();
          break;
        case 'prev':
          this.wgoHelper.prev();
          break;
        case 'next':
          this.wgoHelper.next();
          break;
        case 'next10':
          this.wgoHelper.next10();
          break;
        case 'last':
          this.wgoHelper.last();
          break;
      }
    },
  },
  beforeDestroy() {
    removeCanvasOnDestroy(this.$el);
  },
};

export default mixinObject;
