<template>
  <div id="kifu-tournament" class="relative p-md h-full overflow-scroll">
    <RowItem
      v-for="tournament in tournaments"
      class="mb-sm"
      :key="tournament.id"
      @click="goToKifus(tournament.id)"
    >
      {{ tournament.name }}
    </RowItem>
    <Empty
      v-if="!$apollo.loading"
      v-show="tournaments.length == 0"
      class="h-full"
      text="目前沒有此分類的比賽"
    ></Empty>
    <infinite-loading
      v-if="tournaments.length > 0 && hasMore"
      @infinite="infiniteHandler"
    ></infinite-loading>
  </div>
</template>

<script>
import Empty from '@/components/Common/Empty';
import GET_TOURNAMENTS from '@/graphql/queries/GetTournaments.gql';
import UPDATE_HEADER_CONFIG from '@/graphql/mutations/UpdateHeaderConfig.gql';

export default {
  name: 'KifuTournament',
  components: {
    Empty,
  },
  apollo: {
    tournaments: {
      query: GET_TOURNAMENTS,
      update: (data) => data?.tournaments?.tournaments || [],
      variables() {
        return {
          filter: {
            limit: 20,
            page: 1,
            tagId: Number(this.$route.params.id),
            orderBy: [
              {
                column: 'seq',
                reverse: false,
              },
            ],
          },
        };
      },
    },
  },
  data() {
    return {
      tournaments: [],
      page: 1,
      hasMore: true,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$apollo.mutate({
        mutation: UPDATE_HEADER_CONFIG,
        variables: {
          headerConfig: {
            title: '比賽列表',
            left: 'back',
            middle: 'title',
          },
        },
      });
    });
  },
  methods: {
    goToKifus(tournamentId) {
      this.$router.push({name: 'kifus', query: {tournamentId}});
    },
    infiniteHandler() {
      this.page += 1;
      this.$apollo.queries.tournaments.fetchMore({
        variables: {
          filter: {
            limit: 20,
            page: 1,
            tagId: Number(this.$route.params.id),
          },
        },
        updateQuery: (previousResult, {fetchMoreResult}) => {
          const currentItems = previousResult?.tournaments?.tournaments || [];
          const newItems = fetchMoreResult?.tournaments?.tournaments || [];

          const count = fetchMoreResult?.tournaments?.count;
          if (currentItems.length + newItems.length >= count) {
            this.hasMore = false;
          }

          return {
            tournaments: {
              __typename: previousResult.tournaments.__typename,
              kifus: [...currentItems, ...newItems],
              count,
            },
          };
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#kifu-tournament {
}
</style>
