import UPDATE_LOGIN_MODAL_SHOW from '@/graphql/mutations/UpdateLoginModalShow.gql';
import {apolloClient} from '@/vueApollo';

export const auth = {
  bind(element, binding, vnode) {
    const handlers = vnode.data?.on || vnode.componentOptions?.listeners;
    const _this = vnode.context;

    element.addEventListener('click', (event) => {
      const serializedState = apolloClient.cache.extract();
      const isLogin = Object.values(serializedState).some(
        (value) => value.__typename == 'User' && value.role,
      );

      if ((binding.value == null || binding.value === true) && !isLogin) {
        _this.$Message.error('請先登入');
        _this.$apollo.mutate({
          mutation: UPDATE_LOGIN_MODAL_SHOW,
          variables: {
            isLoginModalShow: true,
          },
        });
      } else {
        handlers.auth();
      }
      if (binding.modifiers.stop) {
        event.stopPropagation();
      }
    });
  },
};
