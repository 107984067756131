import Vue from 'vue';
import Cookies from 'js-cookie';
import VueApollo from 'vue-apollo';
// import { createApolloClient, restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client';
import {createApolloClient} from 'vue-cli-plugin-apollo/graphql-client';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';

import typeDefs from './graphql/typeDefs';
import resolvers from './graphql/resolvers';
import {
  defaultHeaderConfig,
  defaultSgf,
  defaultSetting,
} from './graphql/defaultState';
import GET_USER from './graphql/queries/GetUser.gql';
import {login as loginApi, logout as logoutApi} from '@/lib/userApi';
import {GoogleAuth} from '@codetrix-studio/capacitor-google-auth';

// Http endpoint
const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP;
const socketEndpoint = process.env.VUE_APP_GRAPHQL_SOCKET;

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [],
    },
  },
});

const cache = new InMemoryCache({
  fragmentMatcher,
});

Vue.use(VueApollo);

// Files URL root
export const filesRoot =
  process.env.VUE_APP_FILES_ROOT ||
  httpEndpoint.substr(0, httpEndpoint.indexOf('/graphql'));

Vue.prototype.$filesRoot = filesRoot;

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  credentials: 'include',
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  // wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:4000/graphql',
  wsEndpoint: socketEndpoint,
  // LocalStorage token
  // tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  // link: myLink

  httpLinkOptions: {
    credentials: 'include',
  },

  // Override default cache
  cache,
  typeDefs,
  resolvers,

  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
};

// Create apollo client
export const {apolloClient, wsClient} = createApolloClient({
  ...defaultOptions,
  // ...options,
});
apolloClient.wsClient = wsClient;

initialCache();

let isLogout = false;

// Call this in the Vue app file
export function createProvider() {
  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: 'cache-and-network',
      },
    },
    errorHandler(error) {
      if (
        !isLogout &&
        JSON.parse(localStorage.getItem('isLogin')) &&
        error.message === 'GraphQL error: No session!'
      ) {
        logout();
      }
      console.log(
        '%cError',
        'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
        error.message,
      );
    },
  });

  return apolloProvider;
}

export async function login(
  {authId, password, idToken, platform, appleCode},
  authType = 'LOCAL',
) {
  // if (typeof localStorage !== 'undefined' && token) {
  //   localStorage.setItem(AUTH_TOKEN, token);
  // }
  // if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);

  await loginApi({
    authType,
    authId,
    password,
    idToken,
    platform,
    appleCode,
  });
  const {data, errors} = await apolloClient.query({
    query: GET_USER,
    errorPolicy: 'all',
  });
  if (errors && errors[0] && errors[0].message === 'No session!') {
    throw '帳號無法使用，請聯繫管理員';
  }
  if (!data || !data.user) {
    throw '登入失敗';
  }
  localStorage.setItem('user', JSON.stringify(data.user));
  localStorage.setItem('authType', authType);
  localStorage.setItem('isLogin', true);

  await apolloClient.clearStore();
  // await apolloClient.resetStore();
  await initialCache();
  await apolloClient.reFetchObservableQueries();
}

export async function logout() {
  isLogout = true;
  await logoutApi();
  if (typeof localStorage !== 'undefined') {
    if (localStorage.getItem('authType') === 'GOOGLE') {
      GoogleAuth.signOut();
    }
    localStorage.removeItem('user');
    localStorage.removeItem('setting');
    localStorage.removeItem('authType');
    localStorage.setItem('isLogin', false);
  }
  Cookies.remove('connect.sid');
  await apolloClient.clearStore();

  await initialCache();
  isLogout = false;

  location.href = '/#/login';
  location.reload();
}

async function initialCache() {
  const localSetting =
    localStorage.getItem('setting') &&
    JSON.parse(localStorage.getItem('setting'));
  await apolloClient.writeData({
    data: {
      isKeyboardShow: false,
      isLoginModalShow: false,
      isLoading: false,
      headerConfig: JSON.parse(JSON.stringify(defaultHeaderConfig)),
      kifu: defaultSgf,
      setting: localSetting || JSON.parse(JSON.stringify(defaultSetting)),
    },
  });
}
