<template>
  <div class="comment text-14">
    <span class="text-primary">{{ nickname }}[{{ step }}]：</span>
    <span>{{ content }}</span>
    <span
      v-show="editButton"
      class="text-primary font-bold ml-xs cursor-pointer"
      @click="$emit('edit')"
      ><i class="icon-Edit" />編輯</span
    >
  </div>
</template>

<script>
export default {
  name: 'Comment',
  props: {
    nickname: {
      type: String,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    editButton: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss">
.comment {
  .icon-Edit {
    font-size: 13px;
    &:before {
      @apply text-primary;
    }
  }
}
</style>
