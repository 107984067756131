<template>
  <div id="more" class="p-md">
    <div
      class="
        mb-sm
        text-f-black
        bg-white
        text-center text-14
        pt-md
        pb-md
        pl-lg
        pr-lg
        shadow
        rounded-6
      "
    >
      <BaseAvatar class="avatar" :url="userAvatar" :width="60" />
      <p>
        {{ user != null ? user.name : '訪客' }}
        <span v-if="user != null && user.role.permissions.length !== 0"
          >| {{ user.role.name }}</span
        >
      </p>

      <BaseButton
        v-if="user"
        class="action-btn mt-md text-16 font-bold"
        size="full"
        type="plain"
        @click="onLogout"
        >{{ '登出' }}</BaseButton
      >
      <BaseButton
        v-else
        class="action-btn mt-md text-16 font-bold"
        size="full"
        type="plain"
        @click="onLogin"
        >{{ '綁定帳號' }}</BaseButton
      >
    </div>

    <RowItem icon="icon-AddRounded" class="mb-sm" @click="go('live-kifus')"
      >直播</RowItem
    >
    <!-- <RowItem icon="icon-PodcastBackend" class="mb-sm">影音</RowItem> -->
    <!-- <RowItem icon="icon-TsumeGo" class="mb-sm">死活</RowItem> -->
    <!-- <RowItem icon="icon-Sword" class="mb-sm">對弈</RowItem> -->
    <RowItem icon="icon-Setting" @click="go('setting')" class="mb-sm"
      >設定</RowItem
    >
  </div>
</template>

<script>
import {logout} from '@/vueApollo';
import UPDATE_HEADER_CONFIG from '@/graphql/mutations/UpdateHeaderConfig.gql';
import GET_USER from '@/graphql/queries/GetUser.gql';
import userDefaultAvatar from '@/assets/img/user-default-avatar.png';

export default {
  name: 'More',
  apollo: {
    user: {
      query: GET_USER,
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$apollo.mutate({
        mutation: UPDATE_HEADER_CONFIG,
        variables: {
          headerConfig: {
            title: '更多',
            middle: 'title',
          },
        },
      });
    });
  },
  computed: {
    userAvatar() {
      return this.user != null && this.user.avatar
        ? this.user.avatar
        : userDefaultAvatar;
    },
  },
  methods: {
    go(name) {
      this.$router.push({name});
    },
    onLogin() {
      this.$router.push({name: 'login'});
    },
    onLogout() {
      logout();
    },
  },
};
</script>

<style lang="scss" scoped>
#more {
  .avatar {
    height: 60px;
    margin: auto;
    @apply mb-sm;
  }
  .action-btn {
    @apply bg-white;
    height: 40px;
  }
}
</style>
