<template>
  <div class="search-tournament" :class="{show}">
    <div class="content" :class="containerClass">
      <SearchPanel
        class="relative flex-1"
        :mask="false"
        @close="$emit('close')"
      >
        <template v-slot:header>
          <span
            class="text-i-sky-blue absolute left-md cursor-pointer"
            @click="$emit('close')"
            >取消</span
          >
          <span class="font-bold">請選擇賽事</span>
        </template>
        <template v-slot:content>
          <div v-for="tag in tags" :key="tag.id">
            <div
              class="row flex items-center text-f-black cursor-pointer"
              @click="selectTag(tag)"
            >
              <span>{{ tag.name }}</span>
              <i class="icon-LineRight" />
            </div>
            <BaseLine />
          </div>
          <div
            v-show="tags.length == 0"
            class="flex-1 flex flex-col justify-center items-center"
          >
            <div class="text-f-black-50 text-14">查無結果</div>
          </div>
        </template>
      </SearchPanel>

      <SearchPanel
        class="relative flex-1"
        :mask="false"
        @close="$emit('close')"
      >
        <template v-slot:header>
          <span
            class="text-i-sky-blue absolute left-md cursor-pointer"
            @click="back"
            >返回</span
          >
          <span class="font-bold">請選擇賽事</span>
        </template>
        <template v-slot:content>
          <div class="row flex justify-center">
            <BaseInput
              full
              raw
              icon="icon-Search"
              placeholder="搜尋賽事"
              v-model="searchText"
            ></BaseInput>
          </div>
          <BaseLine />
          <!-- 直接進入列表 -->
          <div v-show="searchText == ''" class="flex-1 flex flex-col">
            <div v-for="tournament in tournaments" :key="tournament.id">
              <div
                class="row flex items-center text-f-black cursor-pointer"
                @click="selectTournament(tournament)"
              >
                <span>{{ tournament.name }}</span>
              </div>
              <BaseLine />
            </div>
            <div
              v-show="tournaments.length == 0"
              class="flex-1 flex flex-col justify-center items-center"
            >
              <div class="text-f-black-50 text-14">查無結果</div>
            </div>
          </div>
          <!-- 搜尋列表 -->
          <div v-show="searchText != ''" class="flex-1 flex flex-col">
            <div v-for="tournament in searchTournaments" :key="tournament.id">
              <div
                class="row flex items-center text-f-black cursor-pointer"
                @click="selectTournament(tournament)"
              >
                <span>{{ tournament.name }}</span>
              </div>
              <BaseLine />
            </div>
            <div
              v-show="searchTournaments.length == 0"
              class="flex-1 flex flex-col justify-center items-center"
            >
              <div class="text-f-black-50 text-14">查無結果</div>
            </div>
          </div>
        </template>
      </SearchPanel>
    </div>
  </div>
</template>

<script>
import SearchPanel from '@/components/Search/SearchPanel';
import GET_TAGS from '@/graphql/queries/GetTags.gql';
import GET_TOURNAMENTS from '@/graphql/queries/GetTournaments.gql';

export default {
  name: 'SearchTournament',
  components: {
    SearchPanel,
  },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
  },
  apollo: {
    tags: {
      query: GET_TAGS,
    },
    tournaments: {
      query: GET_TOURNAMENTS,
      update: (data) => data?.tournaments?.tournaments || [],
      variables() {
        return {
          filter: {
            limit: 100,
            page: 1,
            tagId: this.tag.id,
          },
        };
      },
      skip() {
        return this.tag == null;
      },
    },
    searchTournaments: {
      query: GET_TOURNAMENTS,
      debounce: 500,
      update: (data) => data?.tournaments?.tournaments || [],
      variables() {
        return {
          filter: {
            limit: 20,
            page: 1,
            tagId: this.tag.id,
            name: this.searchText,
          },
        };
      },
      skip() {
        return this.searchText == '';
      },
    },
  },
  data() {
    return {
      tag: null,
      tags: [],
      tournaments: [], // 比賽列表
      searchTournaments: [],
      searchText: '', // 搜尋文字
      containerClass: 'left',
    };
  },
  watch: {
    searchText(value) {
      if (value == '') {
        this.searchTournaments = [];
      }
    },
  },
  methods: {
    async selectTag(tag) {
      this.tag = tag;
      this.containerClass = 'right';
    },
    selectTournament(tournament) {
      this.$emit('select', tournament);
    },
    back() {
      this.tag = null;
      this.containerClass = 'left';
      this.tournaments = [];
    },
  },
};
</script>

<style scoped lang="scss">
.search-tournament {
  @apply fixed h-full w-full;

  .content {
    @apply absolute h-full flex transition-all duration-700;
    width: 200%;

    &.left {
      @apply left-0;
    }
    &.right {
      @apply -left-p100;
    }
  }

  &.show {
    @apply top-0 bg-black-50;
    transition: top 0.7s, background-color 0.7s step-end;
  }
  &:not(.show) {
    @apply top-p100;
    transition: top 0.7s;
    background-color: initial;
  }

  .row {
    @apply w-full items-center pl-md pr-md;
    height: 44px;
  }
}
</style>
