<template>
  <div class="kifu-detail w-full h-full bg-white z-modal text-12">
    <div class="flex flex-col">
      <div class="item m-md big">
        賽事名稱
        <BaseTextArea
          full
          onlyDisplay
          :value="kifu.tournamentName || kifu.series.tournament.name"
        ></BaseTextArea>
      </div>
      <div class="item m-md">
        對局名稱
        <BaseInput full onlyDisplay :value="kifu.series.name"></BaseInput>
      </div>
      <div class="flex">
        <div class="item w-1/2 m-md mr-sm">
          黑方姓名
          <BaseInput
            full
            onlyDisplay
            :value="kifu.blackPlayer.name"
          ></BaseInput>
        </div>
        <div class="item w-1/2 m-md ml-sm">
          白方姓名
          <BaseInput
            full
            onlyDisplay
            :value="kifu.whitePlayer.name"
          ></BaseInput>
        </div>
      </div>
      <div class="item m-md">
        貼目
        <BaseInput full onlyDisplay :value="kifu.komi"></BaseInput>
      </div>
      <div class="item m-md">
        勝負
        <BaseInput full onlyDisplay :value="kifu.winningWay"></BaseInput>
      </div>
      <div class="item m-md">
        日期
        <BaseInput
          type="date"
          full
          onlyDisplay
          :value="gameStartedAtText"
        ></BaseInput>
      </div>
    </div>
  </div>
</template>

<script>
import UPDATE_HEADER_CONFIG from '@/graphql/mutations/UpdateHeaderConfig.gql';
import dayjs from 'dayjs';

export default {
  props: {
    kifu: {
      type: Object,
      required: true,
    },
  },
  data() {
    const gameStartedAtText = dayjs(this.kifu.gameStartedAt).format(
      'YYYY-MM-DD',
    );
    return {
      gameStartedAtText,
    };
  },
  created() {
    this.$apollo.mutate({
      mutation: UPDATE_HEADER_CONFIG,
      variables: {
        headerConfig: {
          title: '棋譜資訊',
          leftCustomized: true,
          left: 'back',
          middle: 'title',
        },
      },
    });
    this.$bus.$on('header:back', async () => {
      this.$emit('close');
    });
  },
  beforeDestroy() {
    this.$bus.$off('header:back');
  },
};
</script>

<style scoped lang="scss">
.kifu-detail {
  .item {
    margin-top: 26px;
    height: 50px;
    &.big {
      height: 80px;
    }
  }
}
</style>
