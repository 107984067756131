<template>
  <div>登入中....</div>
</template>

<script>
import {login} from '@/vueApollo';
import {Device} from '@capacitor/device';

export default {
  name: 'AppleAuth',
  async mounted() {
    const code = this.$route.query.code;
    this.loginByAppleCode(code);
  },
  methods: {
    async goBack() {
      const name = this.$route.query.route;
      if (name == 'login') {
        this.$router.push({name: 'more'});
      } else {
        const deviceInfo = await Device.getInfo();
        if (
          deviceInfo.platform === 'ios' ||
          deviceInfo.operatingSystem === 'ios'
        ) {
          this.$router.go(-1);
        } else {
          this.$router.go(-2);
        }
      }
    },
    async loginByAppleCode(code) {
      if (!code) {
        this.$router.push({name: 'login'});
        this.$Message.error('登入失敗');
      } else {
        try {
          await login({appleCode: code}, 'APPLE');
          this.goBack();
          this.$Message.success('登入成功');
        } catch (error) {
          console.log(error);
          this.$router.push({name: 'login'});
          const message = typeof error === 'string' ? error : '登入失敗';
          this.$Message.error(message);
        }
      }
    },
  },
};
</script>
