<template>
  <div
    class="card w-full flex rounded-6 shadow relative bg-white cursor-pointer"
    @click="$emit('click')"
  >
    <div
      v-show="kifu.isCommentedByCommentator"
      class="
        absolute
        left-0
        top-0
        bg-secondary
        text-12 text-white text-center
        rounded-br-6 rounded-tl-6
      "
      style="width: 36px"
    >
      解說
    </div>
    <div class="flex-1 flex flex-col items-center justify-center">
      <BaseAvatar :url="blackPlayerAvatar" color="black" />
      <div class="player-name" style="margin-top: 6px">
        {{ blackPlayerName }}
      </div>
    </div>
    <div class="flex-1 text-center relative" style="width: 0">
      <div v-if="tournamentName || seriesName">
        <div class="text-14 mt-xs truncate max-w-full text-center">
          {{ tournamentName || '無標題' }}
        </div>
        <div class="text-14 truncate max-w-full text-center text-f-black-50">
          {{ seriesName || '無標題' }}
        </div>
      </div>
      <div v-else class="flex items-center justify-center" style="height: 45px">
        <div class="text-14 mt-xs truncate max-w-full text-center">無標題</div>
      </div>
      <WinningWay
        class="absolute bottom-sm text-14"
        :type="kifu.isStreaming ? 'live' : 'primary'"
      >
        {{ winningWay }}
      </WinningWay>
    </div>
    <div class="flex-1 flex flex-col items-center justify-center">
      <BaseAvatar :url="whitePlayerAvatar" color="white" />
      <div class="player-name" style="margin-top: 6px">
        {{ whitePlayerName }}
      </div>
    </div>
    <i
      class="icon-Favorite absolute right-sm top-sm"
      :class="{
        'is-collected': kifu.isCollected,
        'heart-beat': kifu.isCollected,
      }"
      v-auth.stop
      @auth="collectKifu"
    ></i>
    <div class="absolute right-sm -top-lg text-f-black-50">
      {{ gameStartedAtText }}
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import WinningWay from '@/components/Kifu/WinningWay';
import GET_USER from '@/graphql/queries/GetUser.gql';
import UPDATE_PUBLIC_KIFU from '@/graphql/mutations/UpdatePublicKifu.gql';
import UPDATE_PRIVATE_KIFU from '@/graphql/mutations/UpdatePrivateKifu.gql';

export default {
  name: 'Card',
  components: {
    WinningWay,
  },
  props: {
    kifu: {
      type: Object,
      required: true,
    },
    isPublic: {
      type: Boolean,
      default: true,
    },
  },
  apollo: {
    user: {
      query: GET_USER,
    },
  },
  computed: {
    gameStartedAtText() {
      return dayjs(this.kifu.gameStartedAt).format('YYYY/MM/DD');
    },
    tournamentName() {
      return this.kifu?.tournamentName || this.kifu?.series?.tournament?.name;
    },
    seriesName() {
      return this.kifu?.seriesName || this.kifu?.series?.name;
    },
    blackPlayerName() {
      return this.isPublic
        ? this.kifu.blackPlayer.name
        : this.kifu.blackPlayerName;
    },
    whitePlayerName() {
      return this.isPublic
        ? this.kifu.whitePlayer.name
        : this.kifu.whitePlayerName;
    },
    blackPlayerAvatar() {
      return this.isPublic ? this.kifu.blackPlayer.avatarUrl : '';
    },
    whitePlayerAvatar() {
      return this.isPublic ? this.kifu.whitePlayer.avatarUrl : '';
    },
    winningWay() {
      if (this.kifu.winningWay) {
        return this.kifu.winningWay;
      }
      if (this.kifu.isStreaming) {
        return '直播中';
      }
      return '無';
    },
  },
  methods: {
    async collectKifu() {
      const updateMutation = this.isPublic
        ? UPDATE_PUBLIC_KIFU
        : UPDATE_PRIVATE_KIFU;
      const updateResponseName = this.isPublic
        ? 'updatePublicKifu'
        : 'updatePrivateKifu';

      const result = await this.$apollo.mutate({
        mutation: updateMutation,
        variables: {
          input: {
            id: this.kifu.id,
            isCollected: !this.kifu.isCollected,
          },
        },
      });
      this.kifu.isCollected = result?.data?.[updateResponseName]?.isCollected;
    },
  },
};
</script>

<style scoped lang="scss">
.card {
  height: 78px;
  .player-name {
    @apply truncate;
    max-width: 100px;
  }
  .icon-Favorite {
    &:before {
      @apply text-black-10;
    }
    &.is-collected:before {
      @apply text-i-peach;
    }
  }
}

.card + .card {
  margin-top: 36px;
}
</style>
