<template>
  <div
    class="
      variation-tag
      rounded-6
      bg-i-orange
      text-white text-12
      flex
      items-center
      justify-center
    "
    @click="$emit('click')"
  >
    變化圖{{ step }}-{{ serial }}
  </div>
</template>

<script>
export default {
  name: 'VariationTag',
  props: {
    step: {
      type: Number,
      required: true,
    },
    serial: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.variation-tag {
  width: 91px;
  height: 30px;
}
</style>
