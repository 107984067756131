<template>
  <div class="empty w-full h-full flex justify-center items-center">
    <div class="text-center">
      <i class="icon-EmptyBox" />
      <div class="text-f-black-50 mt-lg">{{ text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    text: {
      type: String,
      default: '找不到資料',
    },
  },
};
</script>

<style scoped lang="scss">
.empty {
  i {
    font-size: 100px;
    &:before {
      @apply text-i-gray;
    }
  }
}
</style>
