<template>
  <div class="search-player">
    <SearchPanel :show="show" @close="$emit('close')">
      <template v-slot:header>
        <span
          class="text-i-sky-blue absolute left-md cursor-pointer"
          @click="$emit('close')"
          >取消</span
        >
        <span class="font-bold">{{ title }}</span>
      </template>
      <template v-slot:content>
        <div class="row flex justify-center">
          <BaseInput
            full
            raw
            icon="icon-Search"
            placeholder="搜尋棋士"
            v-model="searchText"
          ></BaseInput>
        </div>
        <BaseLine />
        <div v-for="player in players" :key="player.id">
          <div
            class="row flex items-center text-f-black cursor-pointer"
            @click="selectItem(player)"
          >
            <BaseAvatar :url="player.avatarUrl"></BaseAvatar>
            <span class="ml-md">{{ player.name }}</span>
            <!-- <i v-show="player.isChecked" class="icon-Check" /> -->
          </div>
          <BaseLine />
        </div>
        <div
          v-show="players.length == 0"
          class="flex-1 flex flex-col justify-center items-center"
        >
          <div class="text-f-black-50 text-14">查無結果</div>
          <div class="text-f-black text-16 mt-sm">
            是否直接新增{{ searchText }}棋士
          </div>
          <BaseButton class="mt-sm" size="small" @click="createPlayer"
            >確定新增</BaseButton
          >
        </div>
      </template>
    </SearchPanel>
  </div>
</template>

<script>
import SearchPanel from '@/components/Search/SearchPanel';
import GET_PLAYERS from '@/graphql/queries/GetPlayers.gql';
import CREATE_PLAYER from '@/graphql/mutations/CreatePlayer.gql';

export default {
  name: 'SearchPlayer',
  components: {
    SearchPanel,
  },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
    },
  },
  apollo: {
    players: {
      query: GET_PLAYERS,
      debounce: 500,
      update: (data) => data?.players?.players || [],
      variables() {
        const filter = {
          limit: 100,
          page: 1,
          name: this.searchText,
        };
        return {
          filter,
        };
      },
    },
  },
  data() {
    return {
      searchText: '', // 搜尋文字
      players: [], // 棋手列表
    };
  },
  methods: {
    selectItem(player) {
      this.$emit('select', player);
    },
    async createPlayer() {
      try {
        const player = await this.$apollo.mutate({
          mutation: CREATE_PLAYER,
          variables: {
            input: {
              name: this.searchText,
              avatarUrl: '',
            },
          },
        });
        this.$Message.success('新增使用者成功');
        this.$emit('select', player);
      } catch (error) {
        console.log(error);
        this.$Message.error('新增使用者錯誤');
      }
    },
  },
};
</script>

<style scoped lang="scss">
.search-player {
  .row {
    @apply w-full items-center pl-md pr-md;
    height: 44px;
  }
}
</style>
