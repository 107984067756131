<template>
  <div class="kifu-controll flex flex-wrap items-center justify-center">
    <div class="w-full flex items-center justify-center">
      <button class="flex items-center justify-center">
        <i class="icon-Rewind" @click="$emit('click', 'prev')" />
      </button>
      <button class="ml-sm flex items-center justify-center">
        <i class="icon-Play" @click="$emit('click', 'next')" />
      </button>
    </div>
    <div class="w-full flex items-center justify-center mt-md">
      <button class="flex items-center justify-center">
        <i class="icon-Rewind10" @click="$emit('click', 'first')" />
      </button>
      <button class="ml-sm flex items-center justify-center">
        <i class="icon-Rewind1" @click="$emit('click', 'prev10')" />
      </button>
      <button class="ml-sm flex items-center justify-center">
        <i class="icon-FastForward1" @click="$emit('click', 'next10')" />
      </button>
      <button class="ml-sm flex items-center justify-center">
        <i class="icon-FastForward10" @click="$emit('click', 'last')" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KifuControll',
};
</script>

<style scoped lang="scss">
.kifu-controll {
  button {
    width: 36px;
    height: 36px;
    @apply bg-primary rounded-6;
  }
  i {
    font-size: 20px;
    &:before {
      @apply text-white;
    }
  }
}
</style>
