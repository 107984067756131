<template>
  <div id="private-kifu" class="relative flex flex-col h-full max-h-full">
    <!-- 上方區塊 -->
    <div
      class="
        bg-background
        border-b border-solid border-gray
        p-sm
        w-full
        overflow-y-scroll
      "
      :class="
        ['view', 'single'].includes(action) ? '' : 'flex-1 flex items-center'
      "
      :style="isOnlyBoard ? {height: 'calc(100% - 56px)'} : {}"
    >
      <!-- 使用者資訊 -->
      <div
        v-show="!isOnlyBoard && ['view', 'single'].includes(action)"
        class="flex justify-between"
      >
        <div class="flex items-center">
          <BaseAvatar color="black" />
          <div class="player-name ml-sm">{{ kifu.blackPlayerName }}</div>
        </div>
        <div class="flex items-center text-primary">
          {{ showStep }} / {{ showCompleteStep }}
        </div>
        <div class="flex items-center">
          <div class="player-name text-right">{{ kifu.whitePlayerName }}</div>
          <BaseAvatar class="ml-sm" color="white" />
        </div>
      </div>
      <!-- 棋盤 -->
      <Board
        id="player"
        class="mt-13"
        :testPlay="isTestPlay"
        :lock="isBoardLock"
        :full="isOnlyBoard ? 'height' : 'width'"
      ></Board>
    </div>
    <!-- Tab -->
    <div v-show="!isOnlyBoard && action == 'view'" class="bg-white">
      <BaseTab :items="tabItems" :active="tab" @update="setTab"></BaseTab>
    </div>
    <!-- Tab內容 -->
    <div
      v-show="!isOnlyBoard && ['view', 'single'].includes(action)"
      class="bg-white flex-1 flex flex-col overflow-y-scroll"
      style="min-height: 80px"
    >
      <!-- tab: 解說 -->
      <div v-show="tab == 'comment'" class="tab-content">
        <Comment
          v-for="(comment, index) in showComments"
          :key="index"
          :nickname="(user && user.name) || ''"
          :step="comment.step"
          :content="comment.content"
          @edit="editComment(comment)"
        ></Comment>
        <div v-show="showComments.length == 0" class="py-sm">
          <BaseButton @click="action = 'input'" size="full"
            >+新增解說</BaseButton
          >
        </div>
      </div>
      <!-- tab: 變化圖 -->
      <div v-show="tab == 'variation'" class="tab-content">
        <div v-show="action == 'view'" class="h-full">
          <div v-show="showVariations.length > 0" class="flex flex-wrap">
            <VariationTag
              v-for="(variation, index) in showVariations"
              :key="index"
              class="mr-sm mb-xs"
              :step="variation.step"
              :serial="index + 1"
              @click="editVariation(variation)"
            ></VariationTag>
            <div
              class="add-more bg-i-orange text-i-white"
              @click="wantInputVariation"
            >
              <i class="icon-Add" />
            </div>
          </div>
          <div v-show="showVariations.length == 0" class="py-sm">
            <BaseButton type="warning" @click="wantInputVariation" size="full"
              >+新增變化圖</BaseButton
            >
          </div>
        </div>
      </div>
    </div>
    <!-- Footer -->
    <div class="flex flex-wrap items-center border-t border-solid border-gray">
      <!-- footer: 新增解說 -->
      <div
        v-show="action == 'input' && tab == 'comment'"
        class="kifu-footer kifu-footer-item pl-md pr-md"
      >
        <BaseInput
          v-model="commentContent"
          :keyboardShowKeepBottom="isKeyboardShow"
          :placeholder="'請輸入第' + step + '手解說文字'"
          :full="true"
          @confirm="hideKeyboard"
        ></BaseInput>
      </div>
      <!-- footer: 編輯解說 -->
      <div v-if="action == 'edit' && tab == 'comment'" class="kifu-footer">
        <div class="kifu-footer-item pl-md pr-md">
          <BaseInput
            v-model="commentContent"
            :keyboardShowKeepBottom="isKeyboardShow"
            :placeholder="'請輸入第' + step + '手解說文字'"
            :full="true"
            @confirm="hideKeyboard"
          ></BaseInput>
        </div>
        <BaseLine v-show="!isOnlyBoard" class="w-full" />
        <div v-show="!isOnlyBoard">
          <div
            class="
              kifu-footer-item
              text-i-red
              font-bold
              flex
              items-center
              justify-center
            "
            @click="deleteComment"
          >
            <i class="icon-Delete"></i>刪除解說
          </div>
        </div>
      </div>
      <!-- footer: 新增變化圖 -->
      <div v-show="action == 'input' && tab == 'variation'" class="kifu-footer">
        <div class="kifu-footer-item pl-md pr-md">
          <BaseInput
            v-model="commentContent"
            :keyboardShowKeepBottom="isKeyboardShow"
            :placeholder="
              '請輸入變化圖' +
                variationStep +
                '-' +
                (showVariations.length + 1) +
                '說明文字'
            "
            :full="true"
          ></BaseInput>
        </div>
        <BaseLine class="w-full" />
      </div>
      <!-- footer: 編輯變化圖 -->
      <div v-if="action == 'edit' && tab == 'variation'" class="kifu-footer">
        <BaseInput
          v-model="commentContent"
          :keyboardShowKeepBottom="isKeyboardShow"
          :placeholder="
            '請輸入變化圖' +
              selectedVariation.step +
              '-' +
              selectedVariationSerial +
              '說明文字'
          "
          :full="true"
          @confirm="hideKeyboard"
        ></BaseInput>
        <BaseLine v-show="!isOnlyBoard" class="w-full" />
      </div>
      <!-- footer: 棋譜控制列 -->
      <KifuControll
        v-show="!isOnlyBoard && isKifuControlShow"
        :value="showStep"
        :max="completeStep"
        :testPlay="isTestPlay"
        :coordinate="isCoordinatesShow"
        :variationShow="isVariationShow"
        @input="updateStep"
        @click="onBoardControlerClick"
        @update-coordinate="toggleCoordinates"
        @toggle-step="toggleStep"
        @update-test-play="toggleTestPlay"
        @leave-variation="reset"
        class="w-full"
      ></KifuControll>
      <!-- footer: 刪除變化圖 -->
      <div v-if="action == 'edit' && tab == 'variation'" class="kifu-footer">
        <BaseLine v-show="!isOnlyBoard" class="w-full" />
        <div v-show="!isOnlyBoard">
          <div
            class="
              kifu-footer-item
              text-i-red
              font-bold
              flex
              items-center
              justify-center
            "
            @click="deleteVariation"
          >
            <i class="icon-Delete"></i>刪除變化圖
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <KifuFormPrivate
      class="absolute top-0 left-0"
      v-if="isKifuDetailShow"
      mode="update"
      :kifu="kifu"
      :sgf="kifu.sgf"
      @close="closeKifuDetail"
    ></KifuFormPrivate>
  </div>
</template>

<script>
import WgoHelper from 'wgo-helper';
import boardMixin from '@/mixins/boardMixin';
import loadingMixin from '@/mixins/loadingMixin';
import kifuMixin from '@/mixins/kifuMixin';
import Board from '@/components/Common/Board';
import KifuControll from '@/components/Kifu/KifuControll';
import VariationTag from '@/components/Kifu/VariationTag';
import KifuFormPrivate from '@/components/Kifu/KifuFormPrivate';
import Comment from '@/components/Kifu/Comment';
import GET_SETTING from '@/graphql/queries/GetSetting.gql';
import GET_USER from '@/graphql/queries/GetUser.gql';
import GET_PRIVATE_KIFU from '@/graphql/queries/GetPrivateKifu.gql';
import READ_KEYBOARD_SHOW from '@/graphql/queries/ReadKeyboardShow.gql';
import UPDATE_HEADER_CONFIG from '@/graphql/mutations/UpdateHeaderConfig.gql';
import UPDATE_PRIVATE_KIFU from '@/graphql/mutations/UpdatePrivateKifu.gql';

export default {
  name: 'PrivateKifu',
  components: {
    Board,
    KifuControll,
    VariationTag,
    Comment,
    KifuFormPrivate,
  },
  mixins: [boardMixin, loadingMixin, kifuMixin],
  apollo: {
    setting: {
      query: GET_SETTING,
    },
    user: {
      query: GET_USER,
      result({data, loading}) {
        if (!loading) {
          if (!data || !data.user) {
            this.$router.push({name: 'home'});
          }
        }
      },
    },
    isKeyboardShow: {
      query: READ_KEYBOARD_SHOW,
    },
    privateKifu: {
      query: GET_PRIVATE_KIFU,
      variables() {
        return {
          id: Number(this.$route.params.id),
        };
      },
      result({data, loading}) {
        if (!loading && data && !this.kifu.id) {
          this.kifu = data.privateKifu;
          if (!this.kifu.seriesName) {
            this.kifu.seriesName = '';
          }
          this.comments = JSON.parse(JSON.stringify(this.kifu.comments || []));
          this.variations = JSON.parse(
            JSON.stringify(this.kifu.variations || []),
          );
          this.initialPlayer(this.kifu.sgf);
        }
      },
    },
  },
  data() {
    /**
     * tab: variation 變化圖
     *   action: view 檢視變化圖
     *   action: input 新增變化圖
     *   action: single 檢視單一變化圖
     */
    return {
      updateFromResponse: true,
      updateMutation: UPDATE_PRIVATE_KIFU,
      updateResponseName: 'updatePrivateKifu',
      tabItems: [
        {text: '解說', value: 'comment'},
        {text: '變化圖', value: 'variation'},
      ],
      kifu: {
        blackPlayerName: '',
        whitePlayerName: '',
        tournamentName: '',
        seriesName: '',
      },
    };
  },
  computed: {
    permissions() {
      return this.user?.role?.permissions || [];
    },
    showComments() {
      return this.comments.filter((comment) => comment.step == this.step);
    },
    isBoardLock() {
      return !this.isTestPlay;
    },
  },
  methods: {
    initialPlayer(sgf = '(;CA[big5]SZ[19])') {
      const theme = {stoneHandler: 'GLOW'};
      this.wgoHelper = new WgoHelper('game', 'player', sgf, {
        theme,
      });

      this.wgoHelper.on('play', () => {
        this.$playSound('play');
      });
      this.wgoHelper.on('update', () => {
        this.capCount = this.wgoHelper.captureCount;
        this.turnColor = this.wgoHelper.turnColor;
        if (this.isTestPlay) {
          this.testPlayCompleteStep = this.wgoHelper.completeStep;
        }
        this.completeStep = this.wgoHelper.completeStep;
        if (this.isTestPlay) {
          this.testPlayStep = this.wgoHelper.step;
        } else {
          this.step = this.wgoHelper.step;
        }
      });
      this.isCoordinatesShow = this.wgoHelper.toggleCoordinates(true);
      this.wgoHelper.last();
      this.capCount = this.wgoHelper.captureCount;
      this.turnColor = this.wgoHelper.turnColor;
      this.completeStep = this.wgoHelper.completeStep;
      this.step = this.wgoHelper.step;
    },
    setTab(value) {
      this.tab = value;
    },
    initialHeaderConfig() {
      this.$bus.$off('header:next');
      this.$bus.$off('header:close');
      this.$nextTick(() => {
        this.$apollo.mutate({
          mutation: UPDATE_HEADER_CONFIG,
          variables: {
            headerConfig: {
              title: '記譜',
              left: 'back',
              middle: 'title',
              right: 'next',
              nextText: '編輯',
            },
          },
        });
        this.$bus.$on('header:next', () => {
          this.isKifuDetailShow = true;
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#private-kifu {
  .player-name {
    @apply truncate;
    width: 65px;
  }
  .tab-content {
    @apply flex-1 pt-sm pb-sm pl-md pr-md break-words;
  }
  .kifu-footer {
    @apply w-full flex flex-wrap items-center justify-center;
  }
  .kifu-footer-item {
    height: 56px;
    @apply w-full pt-sm pb-sm;
  }
  .add-more {
    @apply rounded-6 flex justify-center items-center cursor-pointer;
    width: 30px;
    height: 30px;
    i {
      &:before {
        @apply text-i-white;
      }
    }
  }
  .icon-Delete {
    &:before {
      @apply text-18 text-i-red mr-xs;
    }
  }
}
</style>
