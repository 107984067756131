<template>
  <div
    class="board rounded-10 w-full"
    :style="boardStyle"
    :class="[
      boardClass,
      {
        'is-test-play': testPlay,
      },
    ]"
  ></div>
  <!-- <div
    class="board bg-board1 rounded-10"
    :class="[boardClass, {
      'is-test-play': testPlay,
      'w-full': full == 'width',
      'h-full': full == 'height',
    }]"></div> -->
</template>

<script>
import GET_SETTING from '@/graphql/queries/GetSetting.gql';
import BoardBgDark from '@/assets/img/board-bg-dark-brown.png';
import BoardBgLight from '@/assets/img/board-bg-light-brown.png';
import getDeviceInfo from '@/lib/getDeviceInfo';
const {viewMode, isCapacitorApp, device} = getDeviceInfo();

export default {
  name: 'Board',
  apollo: {
    setting: {
      query: GET_SETTING,
    },
  },
  props: {
    testPlay: {
      type: Boolean,
      default: false,
    },
    lock: {
      type: Boolean,
      default: false,
    },
    full: {
      type: String,
      default: 'width',
      validator(value) {
        return ['width', 'height'].includes(value);
      },
    },
  },
  data() {
    return {
      setting: {},
    };
  },
  computed: {
    boardClass() {
      return [this.lock ? 'pointer-events-none' : ''];
    },
    boardStyle() {
      const boardBg =
        this.setting.boardColor === '2' &&
        !['web', 'pad'].includes(viewMode) &&
        (!isCapacitorApp || device !== 'ios')
          ? BoardBgDark
          : BoardBgLight;

      return {
        backgroundImage: `url('${boardBg}')`,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.board {
  background-size: cover;
  &.is-test-play {
    box-shadow: 0 0 0 4px #f0a141;
  }
}
</style>
