<template>
  <div
    class="
      winning-way
      text-center
      w-full
      rounded-13
      overflow-hidden
      whitespace-nowrap
    "
    :class="type"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'WinningWay',
  props: {
    type: {
      type: String,
      default: 'primary',
      validator(value) {
        return ['primary', 'live'].includes(value);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.winning-way {
  &.primary {
    @apply bg-primary text-white;
  }
  &.live {
    @apply bg-red text-white;
  }
}
</style>
