var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-input relative text-14 flex",class:[
    _vm.size,
    {
      error: _vm.error,
      full: _vm.full,
      raw: _vm.raw,
      'has-icon': _vm.icon,
      'has-value': _vm.value && _vm.value.length > 0,
      'keyboard-show-keep-bottom': _vm.keyboardShowKeepBottom,
    } ],on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('submit')},"click":function($event){return _vm.$emit('click')}}},[_c('div',{staticClass:"relative flex-1 h-full w-full flex items-center justify-center"},[(_vm.icon)?_c('i',{staticClass:"icon-left",class:_vm.icon}):_vm._e(),_c('input',{class:{'pointer-events-none': _vm.onlyDisplay},attrs:{"type":_vm.type,"placeholder":_vm.placeholder,"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"input":_vm.update}}),_c('i',{directives:[{name:"show",rawName:"v-show",value:(
        !_vm.right && _vm.value != null && _vm.value != '' && !_vm.disabled && _vm.type != 'date'
      ),expression:"\n        !right && value != null && value != '' && !disabled && type != 'date'\n      "}],staticClass:"icon-CrossCircle icon-right",on:{"click":_vm.clear}}),_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.right == 'select'),expression:"right == 'select'"}],staticClass:"icon-LineDown icon-right"}),_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.right == 'submit'),expression:"right == 'submit'"}],staticClass:"icon-Send icon-right",on:{"click":function($event){return _vm.$emit('submit')}}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.keyboardShowKeepBottom),expression:"keyboardShowKeepBottom"}],staticClass:"ml-md mr-md flex items-center text-i-dutch-blue text-16",staticStyle:{"width":"32px"},on:{"click":function($event){return _vm.$emit('confirm')}}},[_vm._v(" 確定 ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.keyboardShowKeepBottom),expression:"keyboardShowKeepBottom"}],staticClass:"mask"})])}
var staticRenderFns = []

export { render, staticRenderFns }