<template>
  <div class="w-full h-full" v-if="value">
    <div
      class="
        fixed
        z-modal
        bg-black-50
        px-4
        inset-0
        flex
        justify-center
        items-center
        transition-opacity
      "
      @click.self="close"
    >
      <div
        class="relative w-auto my-6 mx-auto max-w-6xl transform transition-all"
      >
        <!--content-->
        <div
          style="min-width: 272px"
          class="
            border-0
            rounded-20
            shadow-lg
            relative
            flex flex-col
            w-full
            bg-white
          "
        >
          <!--header-->

          <div
            style="height: 53px"
            class="relative flex justify-center items-center text-f-black p-4"
          >
            <h3 class="font-bold">
              {{ title }}
            </h3>

            <div
              style="right: 17px; top: 17px"
              class="absolute cursor-pointer"
              @click="close"
            >
              <i class="icon-Close"></i>
            </div>
          </div>
          <BaseLine />
          <!--body-->
          <div style="min-height: 160px" class="relative p-md flex-auto">
            <slot></slot>
          </div>

          <!--footer-->
          <div v-show="hasFooter" class="flex items-center justify-end p-md">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseModal',
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    closeOnClickModal: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    hasFooter() {
      return !!this.$slots.footer || !!this.$scopedSlots.footer;
    },
  },
  methods: {
    close() {
      this.$emit('input', false);
    },
  },
};
</script>
