import Vue from 'vue';
import '@/assets/scss/message.scss';
import {convertError} from '@/lib/errors';

const defaultConfig = {
  position: 'top-center',
  hideProgressBar: true,
  closeButton: false,
  duration: 1000 * 3,
};

class Message {
  static success(message, duration = defaultConfig.duration) {
    Vue.$toast.success(
      message,
      Object.assign(defaultConfig, {
        timeout: duration,
      }),
    );
  }

  static info(message, duration = defaultConfig.duration) {
    Vue.$toast.info(
      message,
      Object.assign(defaultConfig, {
        timeout: duration,
      }),
    );
  }

  static error(message, duration = defaultConfig.duration) {
    if (message instanceof Error) {
      message = convertError(message);
    }
    Vue.$toast.error(
      message,
      Object.assign(defaultConfig, {
        timeout: duration,
      }),
    );
  }
}

export default Message;
