<template>
  <div id="my-kifus" class="h-full flex flex-col">
    <div class="bg-white" v-if="isCommentator || this.isCollected">
      <BaseTab :items="tabItems" :active="tab" @update="setTab"></BaseTab>
    </div>
    <div class="relative p-md pb-md pt-0 overflow-scroll flex-1">
      <transition-group name="slide" mode="in-out">
        <Card
          v-for="kifu in showKifus"
          class="mt-lg z-1"
          :key="kifu.id"
          :kifu="kifu"
          :isPublic="tab == 'public'"
          @click="goToKifu(kifu.id)"
        ></Card>
      </transition-group>
      <transition>
        <Empty
          v-if="!$apollo.loading"
          v-show="showKifus.length == 0"
          class="absolute top-0 left-0 z-0 h-full"
          text="目前沒有此分類的棋譜"
        ></Empty>
      </transition>
    </div>
  </div>
</template>

<script>
import loadingMixin from '@/mixins/loadingMixin';
import Card from '@/components/Kifu/Card';
import Empty from '@/components/Common/Empty';
import GET_PUBLIC_KIFUS from '@/graphql/queries/GetPublicKifus.gql';
import GET_PRIVATE_KIFUS from '@/graphql/queries/GetPrivateKifus.gql';
import GET_USER from '@/graphql/queries/GetUser.gql';
import UPDATE_HEADER_CONFIG from '@/graphql/mutations/UpdateHeaderConfig.gql';

export default {
  name: 'MyKifus',
  components: {
    Card,
    Empty,
  },
  mixins: [loadingMixin],
  apollo: {
    user: {
      query: GET_USER,
    },
    publicKifus: {
      query: GET_PUBLIC_KIFUS,
      fetchPolicy: 'cache-and-network',
      update: (data) => data?.publicKifus?.kifus || [],
      skip() {
        return !this.isCommentator && !this.isCollected;
      },
      variables() {
        const filter = {
          limit: 50,
          page: 1,
          orderBy: [
            {
              column: 'gameStartedAt',
              reverse: true,
            },
          ],
        };
        if (this.isCollected) {
          filter.isCollected = true;
        } else {
          filter.createdUserId = this.user?.id;
        }
        return {
          filter,
          isLogin: !!this.user,
        };
      },
    },
    privateKifus: {
      query: GET_PRIVATE_KIFUS,
      fetchPolicy: 'cache-and-network',
      update: (data) => data?.privateKifus?.kifus || [],
      skip() {
        return !this.user;
      },
      variables() {
        const filter = {
          limit: 50,
          page: 1,
        };
        if (this.isCollected) {
          filter.isCollected = true;
        }
        return {
          filter,
        };
      },
    },
  },
  data() {
    return {
      publicKifus: [],
      privateKifus: [],
      tab: 'private',
      tabItems: [
        {text: '個人棋譜', value: 'private'},
        {text: '公開棋譜', value: 'public'},
      ],
    };
  },
  computed: {
    permissions() {
      return this.user?.role?.permissions || [];
    },
    isCommentator() {
      return this.permissions.some(
        (permission) => permission.code == 'SUBMIT_PUBLIC_KIFU',
      );
    },
    showKifus() {
      const kifus = this.tab == 'public' ? this.publicKifus : this.privateKifus;
      if (this.isCollected) {
        return kifus.filter((kifu) => kifu.isCollected);
      }
      return kifus;
    },
    isCollected() {
      return this.$route.query.isCollected;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$apollo.mutate({
        mutation: UPDATE_HEADER_CONFIG,
        variables: {
          headerConfig: {
            title: vm.isCollected ? '收藏棋譜' : '我的棋譜',
            left: 'back',
            middle: 'title',
          },
        },
      });
    });
  },
  methods: {
    goToKifu(id) {
      if (this.tab == 'public') {
        this.$router.push({name: 'kifu', params: {id}});
      } else {
        this.$router.push({name: 'private-kifu', params: {id}});
      }
    },
    setTab(value) {
      this.tab = value;
    },
  },
};
</script>

<style lang="scss" scoped>
#my-kifus {
}
</style>
