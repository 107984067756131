<template>
  <div class="web-home h-full flex flex-col justify-center items-center">
    <img :src="WebLogo" alt="" width="300" />
    <p class="home-slogan">想看更多棋譜請下載 HiGO 圍棋 App</p>
    <div class="btn-container flex justify-center items-center">
      <!-- TODO 待後續正確導向網址 -->
      <a
        href="https://play.google.com/store/apps/details?id=org.higohigo.app"
        target="_blank"
        style="margin-right:32px"
      >
        <img :src="BtnGoogleplay" alt="" width="180"
      /></a>
      <a href="https://apps.apple.com/app/1539379861" target="_blank">
        <img :src="BtnAppstore" alt="" width="180" />
      </a>
    </div>
  </div>
</template>

<script>
import UPDATE_HEADER_CONFIG from '@/graphql/mutations/UpdateHeaderConfig.gql';
import WebLogo from '@/assets/img/web-home-logo.png';
import BtnGoogleplay from '@/assets/img/btn-googleplay.png';
import BtnAppstore from '@/assets/img/btn-appstore.png';

export default {
  data() {
    return {
      WebLogo,
      BtnGoogleplay,
      BtnAppstore,
    };
  },
  created() {
    this.$apollo.mutate({
      mutation: UPDATE_HEADER_CONFIG,
      variables: {
        headerConfig: {
          middle: 'logo',
        },
        update: true,
      },
    });
  },
};
</script>

<style lang="scss" scoped>
.web-home {
  .home-slogan {
    font-size: 26px;
    margin-top: 40px;
    margin-bottom: 24px;
    @apply text-f-black font-bold;
  }
}
</style>
