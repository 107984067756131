<template>
  <div class="search-series">
    <SearchPanel :show="show" @close="$emit('close')">
      <template v-slot:header>
        <span
          class="text-i-sky-blue absolute left-md cursor-pointer"
          @click="$emit('close')"
          >取消</span
        >
        <span class="font-bold">請選擇對局</span>
      </template>
      <template v-slot:content>
        <div class="row flex justify-center">
          <BaseInput
            full
            raw
            icon="icon-Search"
            placeholder="搜尋對局"
            v-model="searchText"
          ></BaseInput>
        </div>
        <BaseLine />
        <div v-for="series in showSerieses" :key="series.id">
          <div
            class="row flex items-center text-f-black cursor-pointer"
            @click="selectItem(series)"
          >
            <span class="ml-md">{{ series.name }}</span>
          </div>
          <BaseLine />
        </div>
        <div
          v-show="showSerieses.length == 0"
          class="flex-1 flex flex-col justify-center items-center"
        >
          <div class="text-f-black-50 text-14">查無結果</div>
        </div>
      </template>
    </SearchPanel>
  </div>
</template>

<script>
import SearchPanel from '@/components/Search/SearchPanel';

export default {
  name: 'SearchSeries',
  components: {
    SearchPanel,
  },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    serieses: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      searchText: '', // 搜尋文字
    };
  },
  computed: {
    showSerieses() {
      return this.serieses.filter((series) =>
        series.name.includes(this.searchText),
      );
    },
  },
  methods: {
    selectItem(series) {
      this.$emit('select', series);
    },
  },
};
</script>

<style scoped lang="scss">
.search-series {
  .row {
    @apply w-full items-center pl-md pr-md;
    height: 44px;
  }
}
</style>
