<template>
  <div>
    <BaseButton type="primary" @click="showModal = true"> 修改密碼 </BaseButton>
    <BaseModal v-model="showModal" title="修改密碼">
      <BaseInput
        v-model="oldPassword"
        class="mb-lg"
        type="password"
        placeholder="請輸入舊密碼"
      ></BaseInput>
      <BaseInput
        v-model="newPassword"
        class="mb-lg"
        type="password"
        placeholder="請輸入新密碼"
      ></BaseInput>
      <BaseInput
        v-model="confirmPassword"
        class="mb-lg"
        type="password"
        placeholder="請再次輸入新密碼"
      ></BaseInput>
      <BaseButton class="w-full" type="primary" @click="submit">
        確定
      </BaseButton>
    </BaseModal>
  </div>
</template>

<script>
import UPDATE_USER_OWN_PASSWORD from '@/graphql/mutations/UpdateUserOwnPassword.gql';

export default {
  data() {
    return {
      showModal: false,
      oldPassword: '',
      password: '',
      newPassword: '',
      confirmPassword: '',
    };
  },
  methods: {
    async submit() {
      if (!this.oldPassword) {
        this.$Message.error('請輸入舊密碼');
        return;
      }
      if (!this.newPassword) {
        this.$Message.error('請輸入新密碼');
        return;
      }
      if (!this.confirmPassword) {
        this.$Message.error('請再次輸入新密碼');
        return;
      }
      if (this.newPassword != this.confirmPassword) {
        this.$Message.error('密碼不一致，請重新輸入');
        return;
      }
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_USER_OWN_PASSWORD,
          variables: {
            input: {
              oldPassword: this.oldPassword,
              newPassword: this.newPassword,
            },
          },
        });
        this.$emit('input', false);
        this.$Message.success('修改密碼成功');
        this.showModal = false;
      } catch (error) {
        console.log(error);
        this.$Message.error(error || '修改密碼失敗');
      }
    },
  },
};
</script>
