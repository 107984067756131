<template>
  <div class="base-checkbox flex">
    <input type="checkbox" :checked="value" @click="change" />
  </div>
</template>

<script>
export default {
  name: 'BaseCheckbox',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    change($event) {
      $event.preventDefault();
      setTimeout(() => {
        this.$emit('input', !this.value);
      }, 50);
    },
  },
};
</script>

<style scoped lang="scss">
.base-checkbox {
  input[type='checkbox'] {
    @apply border border-solid border-gray text-f-black;
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    appearance: none;
    outline: 0;
    cursor: pointer;
    transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
    -webkit-appearance: none;
    &::before {
      @apply border-i-white;
      position: absolute;
      content: '';
      display: block;
      top: 5%;
      left: 33%;
      width: 35%;
      height: 70%;
      border-style: solid;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      opacity: 0;
    }
    &:checked {
      @apply text-i-white border-i-dutch-blue bg-i-dutch-blue;
      &::before {
        opacity: 1;
      }
      ~ label::before {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }
    }
  }
}
</style>
