<template>
  <div class="kifu-form-private w-full h-full bg-white z-modal text-12">
    <div class="flex flex-col">
      <div class="item m-md">
        賽事名稱（選填）
        <BaseInput full v-model="kifu.tournamentName"></BaseInput>
      </div>
      <div class="item m-md">
        對局名稱（選填）
        <BaseInput full v-model="kifu.seriesName"></BaseInput>
      </div>
      <div class="flex">
        <div class="item w-1/2 m-md mr-sm">
          黑方姓名
          <BaseInput full v-model="kifu.blackPlayerName"></BaseInput>
        </div>
        <div class="item w-1/2 m-md ml-sm">
          白方姓名
          <BaseInput full v-model="kifu.whitePlayerName"></BaseInput>
        </div>
      </div>
      <div class="item m-md">
        貼目
        <BaseInput full v-model="kifu.komi"></BaseInput>
      </div>
      <div class="item m-md">
        勝負（選填）
        <BaseInput full v-model="kifu.winningWay"></BaseInput>
      </div>
      <div class="item m-md">
        日期
        <BaseInput type="date" full v-model="gameStartedAtText"></BaseInput>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import TIME from '@/constant/time';
import CREATE_PRIVATE_KIFU from '@/graphql/mutations/CreatePrivateKifu.gql';
import UPDATE_PRIVATE_KIFU from '@/graphql/mutations/UpdatePrivateKifu.gql';
import UPDATE_HEADER_CONFIG from '@/graphql/mutations/UpdateHeaderConfig.gql';

export default {
  props: {
    mode: {
      type: String,
      default: 'create',
      validator(value) {
        return ['create', 'update'].includes(value);
      },
    },
    kifu: {
      type: Object,
      default: () => {
        return {
          tournamentName: '',
          seriesName: '',
          blackPlayerName: '',
          whitePlayerName: '',
          winningWay: '',
          komi: '6.5',
        };
      },
    },
    sgf: {
      type: String,
      default: '(;CA[big5]SZ[19])',
    },
  },
  data() {
    return {
      gameStartedAtText: dayjs(this.kifu.gameStartedAt).format('YYYY-MM-DD'),
    };
  },
  watch: {
    kifu: {
      deep: true,
      immediate: true,
      handler(value) {
        const isAbled =
          value.blackPlayerName &&
          value.whitePlayerName &&
          this.gameStartedAtText;
        this.$apollo.mutate({
          mutation: UPDATE_HEADER_CONFIG,
          variables: {
            headerConfig: {
              title: '棋譜資訊',
              left: 'close',
              middle: 'title',
              right: 'next',
              nextText: '保存',
              nextDisabled: !isAbled,
            },
            update: true,
          },
        });
        this.gameStartedAtText = dayjs(this.kifu.gameStartedAt).format(
          'YYYY-MM-DD',
        );
      },
    },
  },
  created() {
    this.$bus.$on('header:next', async () => {
      if (this.mode == 'create') {
        await this.$apollo.mutate({
          mutation: CREATE_PRIVATE_KIFU,
          variables: {
            input: {
              tournamentName: this.kifu.tournamentName,
              seriesName: this.kifu.seriesName,
              blackPlayerName: this.kifu.blackPlayerName,
              whitePlayerName: this.kifu.whitePlayerName,
              komi: this.kifu.komi,
              gameStartedAt: new Date(this.gameStartedAtText),
              sgf: this.sgf,
              winningWay: this.kifu.winningWay,
            },
          },
        });
        this.$Message.success('已成功提交', 1000 * 1.5);
        setTimeout(() => {
          this.$router.push({name: 'my-kifus'});
        }, TIME.SUCCESS_ROUTER_CHANGE);
      } else {
        await this.$apollo.mutate({
          mutation: UPDATE_PRIVATE_KIFU,
          variables: {
            input: {
              id: this.kifu.id,
              tournamentName: this.kifu.tournamentName,
              seriesName: this.kifu.seriesName,
              blackPlayerName: this.kifu.blackPlayerName,
              whitePlayerName: this.kifu.whitePlayerName,
              komi: this.kifu.komi,
              gameStartedAt: new Date(this.gameStartedAtText),
              sgf: this.sgf,
              winningWay: this.kifu.winningWay,
            },
          },
        });
        this.$Message.success('已成功提交', 1000 * 1.5);
        setTimeout(() => {
          this.$emit('close');
        }, TIME.SUCCESS_ROUTER_CHANGE);
      }
    });

    this.$bus.$on('header:close', async () => {
      this.$emit('close');
    });
  },
  beforeDestroy() {
    this.$bus.$off('header:next');
    this.$bus.$off('header:close');
  },
};
</script>

<style scoped lang="scss">
.kifu-form-private {
  .item {
    margin-top: 26px;
    height: 50px;
    &.big {
      height: 80px;
    }
  }
}
</style>
