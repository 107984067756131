<template>
  <div
    class="base-input relative text-14 flex"
    :class="[
      size,
      {
        error,
        full,
        raw,
        'has-icon': icon,
        'has-value': value && value.length > 0,
        'keyboard-show-keep-bottom': keyboardShowKeepBottom,
      },
    ]"
    v-on:keyup.enter="$emit('submit')"
    @click="$emit('click')"
  >
    <div class="relative flex-1 h-full w-full flex items-center justify-center">
      <i v-if="icon" class="icon-left" :class="icon"></i>
      <input
        :type="type"
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
        :class="{'pointer-events-none': onlyDisplay}"
        @input="update"
      />
      <i
        class="icon-CrossCircle icon-right"
        v-show="
          !right && value != null && value != '' && !disabled && type != 'date'
        "
        @click="clear"
      ></i>
      <i class="icon-LineDown icon-right" v-show="right == 'select'"></i>
      <i
        class="icon-Send icon-right"
        v-show="right == 'submit'"
        @click="$emit('submit')"
      ></i>
      <!-- <i class="icon-Calendar0 icon-right" v-show="type == 'date'"></i> -->
    </div>
    <div
      v-show="keyboardShowKeepBottom"
      class="ml-md mr-md flex items-center text-i-dutch-blue text-16"
      style="width: 32px"
      @click="$emit('confirm')"
    >
      確定
    </div>

    <div class="mask" v-show="keyboardShowKeepBottom"></div>
  </div>
</template>

<script>
export default {
  name: 'BaseInput',
  props: {
    type: {
      // 輸入類型
      type: String,
      default: 'text',
      validator(value) {
        return ['text', 'password', 'number', 'date'].includes(value);
      },
    },
    size: {
      // 大小尺寸
      type: String,
      default: 'medium',
      validator(value) {
        return ['auto', 'large', 'medium', 'small'].includes(value);
      },
    },
    full: {
      // 是否寬度滿版
      type: Boolean,
      default: false,
    },
    value: {
      // input的值
      type: [String, Number],
    },
    icon: {
      // 左側icon
      type: String,
    },
    placeholder: {
      // 沒有value的佔位文字
      type: String,
      default: '',
    },
    disabled: {
      // 是否不可操作 (原生)
      type: Boolean,
      default: false,
    },
    onlyDisplay: {
      // 純顯示，不能修改內容（比起disabled，不帶有原生style）
      type: Boolean,
      default: false,
    },
    raw: {
      // 是否顯示無邊框的純粹input
      type: Boolean,
      default: false,
    },
    error: {
      // 資料錯誤
      type: Boolean,
      default: false,
    },
    right: {
      type: String,
      validator(value) {
        return ['select', 'submit', ''].includes(value);
      },
    },
    keyboardShowKeepBottom: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clear() {
      this.$emit('input', '');
    },
    update(event) {
      this.$emit('input', event.target.value);
    },
  },
};
</script>

<style scoped lang="scss">
.base-input {
  &.has-icon {
    input {
      padding-left: 40px;
    }
  }
  &.has-value {
    i.icon-Send:before,
    i.icon-Calendar0:before {
      @apply text-i-dutch-blue;
    }
  }
  &.error {
    input {
      @apply border border-solid border-i-red;
    }
  }

  &.auto {
    input {
      @apply rounded-6 text-14;
      width: inherit;
      max-width: 100%;
      height: 40px;
    }
    i.icon-left {
      left: 14px;
    }
    i.icon-right {
      right: 14px;
    }
  }
  &.medium {
    input {
      @apply rounded-6 text-14;
      width: 240px;
      height: 40px;
    }
    i.icon-left {
      left: 14px;
    }
    i.icon-right {
      right: 14px;
    }
  }
  &.full {
    @apply w-full;
    input {
      @apply w-full;
    }
  }
  &.keyboard-show-keep-bottom {
    @apply fixed bottom-0;
    height: 56px;
  }

  input {
    &:focus {
      @apply border border-solid border-i-dutch-blue;
      outline: none;
    }
    &:disabled {
      @apply cursor-not-allowed;
    }

    background-color: #ebf1fc;
    padding: 0 15px;

    &[type='date'] {
      -webkit-appearance: none;
    }
    // &[type="date"]::-webkit-inner-spin-button {
    //   display: none;
    // }
    // &[type="date"]::-webkit-calendar-picker-indicator {
    //   display: none;
    // }
    // &[type="date"]::-webkit-clear-button {
    //   display:none;
    // }
  }
  i {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);

    &.icon-left {
      @apply left-0;
    }
    &.icon-right {
      @apply right-0 cursor-pointer;
      &:before {
        @apply text-i-gray;
      }
    }
    &.icon-Send {
      @apply text-18;
    }
  }

  &.raw {
    input {
      background-color: initial;
      padding-left: 0;
      &:focus {
        outline: none;
      }
    }

    &.has-icon {
      input {
        padding-left: 24px;
      }
      i {
        &:before {
          @apply text-i-gray;
        }
      }
      i.icon-left {
        @apply left-0;
      }
      i.icon-right {
        @apply right-0;
      }
    }
  }

  .mask {
    @apply fixed top-0 left-0 w-full bg-black-20;
    height: calc(100% - 56px);
  }
}
</style>
