<template>
  <div
    class="base-pop-menu bg-black-50 z-modal fixed top-0 left-0 w-full h-full"
    :class="{show}"
    @click.self="$emit('close')"
  >
    <div
      class="content fixed bottom-0 bg-i-white w-full rounded-t-10"
      style="min-height: 60px"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BasePopMenu',
  props: {
    show: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss">
.base-pop-menu {
  &.show {
    @apply top-0 bg-black-50;
    .content {
      @apply bottom-0;
    }
  }
  &:not(.show) {
    transition: top 0.7s step-end;
    @apply top-p100;
    background-color: inherit;
    .content {
      @apply -bottom-p100;
    }
  }

  .content {
    @apply transition-all duration-700;
  }
}
</style>
