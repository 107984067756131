<template>
  <div
    class="
      type-block
      flex
      items-center
      justify-center
      rounded-6
      shadow
      bg-white
      text-f-black
      cursor-pointer
    "
    @click="$emit('click')"
  >
    <div class="flex flex-wrap justify-center">
      <i :class="icon"></i>
      <div class="w-full text-center mt-sm">{{ text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TypeBlock',
  props: {
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.type-block {
  .icon-New {
    font-size: 44px;
    &:before {
      @apply text-primary;
    }
  }
  .icon-Comment {
    font-size: 40px;
    &:before {
      @apply text-i-sky-blue;
    }
  }
  .icon-Hot {
    font-size: 44px;
    &:before {
      @apply text-orange;
    }
  }
  .icon-Search {
    font-size: 44px;
    &:before {
      @apply text-i-green;
    }
  }
  .icon-Favorite {
    font-size: 44px;
    &:before {
      @apply text-red;
    }
  }
}
</style>
